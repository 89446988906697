import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import { Chip, IconButton, Tooltip } from '@mui/material';
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import { closeDialog } from '../../../store/fuse/dialogSlice';
import FuseLoading from '../../../../@fuse/core/FuseLoading';
import { getDateDiff } from '../../../services/dateUtil';
import _ from '../../../../@lodash';
import { consumeNotifications, listReceivedUserNotifications } from '../store/receivedNotificationsSlice';
import { showMessage } from '../../../store/fuse/messageSlice';
import { useNavigate } from 'react-router-dom';
import { getOrderStatusByName } from '../order/orderHelper';


function getStatusChip(item, theme) {
  if (item.status) {
    return <Chip
      label={_.startCase(_.lowerCase(getOrderStatusByName(item.status).name))}
      sx={{
        bgcolor:
          theme.palette.order_status[
            _.snakeCase(getOrderStatusByName(item.status).name)
            ],
        color: theme.palette.common.white,
      }}
      size="small"
    />
  }
}

function ReceivedNotificationsDialog(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(({ auth }) => auth.user);
    const username = user.data.email;
    const theme = useTheme();

    const { loading, data } = useSelector(({ journey }) => journey.receivedUserNotifications);

    useEffect(() => {
      dispatch(listReceivedUserNotifications({ username }));
    }, [dispatch]);

    if (loading) {
      return <FuseLoading />;
    }

    if (data.length === 0) {
      dispatch(showMessage({ message: 'You do not have any recent order notifications!', variant: 'info' }));
      return null;
    }

    const results = data.slice(0, 20);

    return (
      <Dialog
        classes={{
          paper: 'm-24',
        }}
        open
        fullWidth
        maxWidth="sm"
      >
        <AppBar position="static" elevation={0}>
          <Toolbar className="flex w-full">
            <Typography variant="subtitle1" color="inherit">
              My Recent Order Notifications
            </Typography>
            <div className="flex-1" />

            <Tooltip title="Mark all notifications as read">
              <IconButton style={{ color: 'white' }}
                          onClick={() => {
                            dispatch(consumeNotifications(data));
                            dispatch(listReceivedUserNotifications({ username }));
                          }}>
                <MarkChatReadIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Close window">
              <IconButton style={{ color: 'white' }} onClick={() => dispatch(closeDialog())}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>


        <List dense sx={{ p: 1 }}>
          {results.map((item) => (
            <ListItem disableGutters key={item.notificationDate}
                      style={item.readDate == null ? { backgroundColor: '#CBF6FF' } : {}}
            >
              <ListItemButton
                onClick={() => {
                  dispatch(consumeNotifications([item]));
                  dispatch(listReceivedUserNotifications({ username }));
                  navigate(`/orders/${item.orderId}`);
                  dispatch(closeDialog());
                }}
                key={item.orderId}
              >
                <ListItemText
                  disableTypography
                  primary={<Typography>
                    <span
                    style={{ fontWeight: 'bold' }}>{item.orderName}</span> is {getStatusChip(item, theme)} (By {item.eventOwner} {getDateDiff(new Date(item.notificationDate))} ago)
                  </Typography>}

                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <DialogActions className="flex flex-row items-center  justify-center">
          <Button
            variant="outlined"
            color="error"
            type="button"
            onClick={() => dispatch(closeDialog())}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
}

export default ReceivedNotificationsDialog;
