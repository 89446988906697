import { API } from 'aws-amplify';

export const graphqlBaseQuery = async (variables) => {
  try {
    const cleanVariables = Object.fromEntries(
      Object.entries(variables).filter(([_, v]) => v !== undefined && v !== null)
    );

    const result = await API.graphql(cleanVariables);

    return { data: result.data };
  } catch (error) {
    const errorData = error.errors ? error.errors[0] : error;
    return {
      error: {
        status: errorData?.status || error.response?.status || 500,
        data: errorData?.message || error.response?.data || 'An error occurred',
      },
    };
  }
};