import React from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.default,
    opacity: 0.7,
  },
  progress: {
    color: theme.palette.secondary.dark,
  },
}));

function BillingSpinner() {
  const classes = useStyles();

  const { loading: loadingPlatform } = useSelector(({ billing }) => billing.platforms);
  const loadingPrices = useSelector((state) =>
    Object.values(state.pricesApi?.mutations)?.some((query) => query.status === 'pending')
  );
  const loadingAccounts = useSelector((state) =>
    Object.values(state.accountsApi?.mutations)?.some((query) => query.status === 'pending')
  );
  const loadingCorrections = useSelector((state) =>
    Object.values(state.correctionsApi?.mutations)?.some((query) => query.status === 'pending')
  );
  const loadingCodes = useSelector((state) =>
    Object.values(state.codesApi?.mutations)?.some((query) => query.status === 'pending')
  );
  const loadingContracts = useSelector((state) =>
    Object.values(state.contractsApi?.mutations)?.some((query) => query.status === 'pending')
  );

  return (
    <div
      className={clsx(
        classes.root,
        `flex absolute z-50 top-0 left-0 w-full h-full items-center justify-center ${
          loadingPlatform ||
          loadingCodes ||
          loadingAccounts ||
          loadingCorrections ||
          loadingPrices ||
          loadingContracts
            ? ''
            : 'hidden'
        }`
      )}
    >
      <CircularProgress className={classes.progress} />
    </div>
  );
}

export default React.memo(BillingSpinner);
