import FormInputDropdown from './FormInputDropdown';
import FuseLoading from '../../@fuse/core/FuseLoading';
import { useGetContractsQuery } from '../main/billing-admin/store/contractsApi';

function FormContractDropdown({ mno, ...props }) {
  const { data: contracts = [], isLoading } = useGetContractsQuery();

  const availableContracts = contracts
    .filter((contract) => contract.mno === mno)
    .sort((c1, c2) => c1.name.localeCompare(c2.name))
    .map((contract) => ({
      value: contract.id,
      label: contract.name,
    }));

  if (isLoading) {
    return <FuseLoading />;
  }

  return <FormInputDropdown {...props} options={availableContracts} />;
}

export default FormContractDropdown;
