import { createApi } from '@reduxjs/toolkit/query/react';
import { showMessage } from 'app/store/fuse/messageSlice';
import history from '@history';
import { billingBaseQuery } from '../utils';

const invalidateCacheHeader = { headers: { 'Cache-Control': 'max-age=0' } };

export const codesApi = createApi({
  reducerPath: 'codesApi',
  baseQuery: billingBaseQuery,
  tagTypes: ['Codes', 'ProductFamilies'],
  endpoints: (builder) => ({
    getCodes: builder.query({
      query: (invalidate = false) => ({
        url: 'codes',
        method: 'GET',
        headers: invalidate ? invalidateCacheHeader.headers : {},
      }),
      transformResponse: (response) =>
        response.map((code) => Object.fromEntries(Object.entries(code).filter(([k, v]) => !!v))),
      providesTags: ['Codes'],
    }),
    saveCode: builder.mutation({
      query: (code) => {
        const filteredCode = Object.fromEntries(Object.entries(code).filter(([k, v]) => !!v));
        return {
          url: 'codes',
          method: code.id ? 'PUT' : 'POST',
          body: filteredCode,
        };
      },
      invalidatesTags: ['Codes'],
      async onQueryStarted(code, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled;
          dispatch(
            showMessage({
              message: code.id ? 'Code saved successfully!' : 'Code created successfully!',
              variant: 'success',
            })
          );
          if (!code.id) {
            history.push({
              pathname: `/billing-admin/codes/${response?.data?.id}`,
            });
          }
        } catch (error) {
          dispatch(
            showMessage({
              message: 'Code already exists!',
              variant: 'error',
            })
          );
        }
      },
    }),
    getProductFamilies: builder.query({
      query: (invalidate = true) => ({
        url: 'product_family',
        method: 'GET',
        headers: invalidate ? invalidateCacheHeader.headers : {},
      }),
      providesTags: ['ProductFamilies'],
    }),
  }),
});

export const { useGetCodesQuery, useSaveCodeMutation, useGetProductFamiliesQuery } = codesApi;
