import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

const contractsAdapter = createEntityAdapter({});

export const { selectAll: selectContracts, selectById: selectContractById } =
  contractsAdapter.getSelectors((state) => state.billing.contracts);

const contractsSlice = createSlice({
  name: 'billing/contracts',
  initialState: contractsAdapter.getInitialState({
    loading: true,
    contractDialog: {
      type: 'new',
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    openNewContractDialog: (state, action) => {
      const { type: actionType, data: actionData } = action.payload;
      state.contractDialog = {
        type: actionType,
        props: {
          open: true,
        },
        data: actionData,
      };
    },
    closeNewContractDialog: (state) => {
      state.contractDialog = {
        type: 'new',
        props: {
          open: false,
        },
        data: null,
      };
    },
  },
});

export const { openNewContractDialog, closeNewContractDialog } = contractsSlice.actions;
export default contractsSlice.reducer;
