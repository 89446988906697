// mnosApi.js
import { createApi } from '@reduxjs/toolkit/query/react';
import { showMessage } from '../../../store/fuse/messageSlice';
import ComponentLogger from 'app/services/logger/ComponentLogger';
import { billingBaseQuery } from '../../billing-admin/utils';

const invalidateCacheHeader = { headers: { 'Cache-Control': 'max-age=0' } };
const logger = new ComponentLogger('MnosSlice');

export const mnosApi = createApi({
  reducerPath: 'mnosApi',
  baseQuery: billingBaseQuery,
  tagTypes: ['Mnos', 'MnosInvalidated'],
  endpoints: (builder) => ({
    getMnos: builder.query({
      query: () => ({
        url: 'mno',
        method: 'GET',
      }),
      transformResponse: (response) => response.sort((a, b) => a.id - b.id),
      providesTags: ['Mnos'],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          logger.error('getMnos', { error });
          dispatch(
            showMessage({
              message: 'An error occurred while fetching the MNO records',
              variant: 'error',
            })
          );
        }
      },
    }),
    getMnosInvalidated: builder.query({
      query: () => ({
        url: 'mno',
        method: 'GET',
        headers: invalidateCacheHeader.headers, // Include invalidateCacheHeader
      }),
      transformResponse: (response) => response.sort((a, b) => a.id - b.id),
      providesTags: ['MnosInvalidated'],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          logger.error('getMnosInvalidated', { error });
          dispatch(
            showMessage({
              message: 'An error occurred while fetching the MNO records',
              variant: 'error',
            })
          );
        }
      },
    }),
    saveMno: builder.mutation({
      query: (mno) => ({
        url: 'mno',
        method: 'PUT',
        body: mno,
      }),
      invalidatesTags: ['MnosInvalidated'],
      async onQueryStarted(mno, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(showMessage({ message: 'MNO is successfully saved', variant: 'success' }));
        } catch (error) {
          dispatch(
            showMessage({
              message: 'An error occurred while saving the MNO',
              variant: 'error',
            })
          );
        }
      },
    }),
  }),
});

export const {
  useGetMnosQuery,
  useGetMnosInvalidatedQuery,
  useSaveMnoMutation,
} = mnosApi;
