import { IconButton, Tooltip } from '@mui/material';
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ApprovalIcon from '@mui/icons-material/ApprovalOutlined';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { openDialog } from 'app/store/fuse/dialogSlice';
import { OrderStatus } from 'app/services/constants';
import { useSearchParams } from 'react-router-dom';
import { updateOrderStatus } from '../store/ordersSlice';
import { getOrderStatus } from '../order/orderHelper';
import AlertDialog from '../../../shared-components/AlertDialog';
import filterUtil from '../../../services/filterUtil';
import withRouter from '../../../../@fuse/core/withRouter';
import { useGetTemplatesQuery } from '../../admin/store/templatesApi';

function OrdersAction(props) {
  const dispatch = useDispatch();
  const { permissions } = useSelector(({ auth }) => auth.user);
  const { data: templates = [] } = useGetTemplatesQuery();
  const user = useSelector(({ auth }) => auth.user);

  function isCloneEnabled(row) {
    if (row.status === OrderStatus.DRAFT.name) return false;
    const template = templates && templates.find((item) => item.id === row.templateId);
    if (!template || template.isDisabled) return false;
    return !moment(row.createdAt).isBefore(moment(template?.journey?.updatedAt));
  }

  function cancelOrder(row) {
    const date = new Date();

    // Do not change description below
    const order = {
      id: row.id,
      mno: row.mno,
      billingAccountId: row.billingAccountId,
      billingCodeId: row.billingCodeId,
      templateId: row.templateId,
      product: row.product,
      status: OrderStatus.CANCELED.name,
      description: `Cancelled by ${user.data.email}`,
      updaterId: user.data.email,
      ownerId: row.ownerId,
      authorizedAmount: 0,
    };
    return dispatch(updateOrderStatus(order));
  }

  function terminateStatus(row) {
    const order = {
      id: row.id,
      mno: row.mno,
      billingAccountId: row.billingAccountId,
      billingCodeId: row.billingCodeId,
      templateId: row.templateId,
      product: row.product,
      status: OrderStatus.TERMINATED.name,
      description: `Terminated by ${user.data.email}`,
      updaterId: user.data.email,
      ownerId: row.ownerId,
    };
    return dispatch(updateOrderStatus(order));
  }

  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page') ? parseInt(searchParams.get('page'), 10) : 0;

  return (
    <>
      {((getOrderStatus(props.cellValues.row).isEditable &&
        filterUtil.checkPermissionsForEdit(props.cellValues.row, permissions, user.role)) ||
        (getOrderStatus(props.cellValues.row).isEditable &&
          props.cellValues.row.status === OrderStatus.SCHEDULED.name &&
          !!permissions.filter((perm) => perm.permission === 'Approve').length)) && (
        <Tooltip title="Edit">
          <IconButton
            aria-label="Edit"
            size="medium"
            onClick={() => props.navigate(`/orders/${props.cellValues.row.id}?page=${page}`)}
          >
            <EditOutlinedIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      )}
      {getOrderStatus(props.cellValues.row).isApprovable &&
        filterUtil.checkPermissionsForApprove(props.cellValues.row, permissions, user.role) && (
          <Tooltip title="Approve">
            <IconButton
              aria-label="Approve"
              size="medium"
              onClick={() => props.navigate(`/orders/${props.cellValues.row.id}`)}
            >
              <ApprovalIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        )}
      {getOrderStatus(props.cellValues.row).isViewable &&
        !(
          getOrderStatus(props.cellValues.row).isEditable &&
          filterUtil.checkPermissionsForEdit(props.cellValues.row, permissions, user.role)
        ) &&
        filterUtil.checkPermissionsForView(props.cellValues.row, permissions) && (
          <Tooltip title="Details">
            <IconButton
              aria-label="details"
              size="medium"
              onClick={() => props.navigate(`/orders/${props.cellValues.row.id}?page=${page}`)}
            >
              <PreviewOutlinedIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        )}
      {isCloneEnabled(props.cellValues.row) &&
        filterUtil.checkPermissionsForEdit(props.cellValues.row, permissions, user.role) && (
          <Tooltip title="Clone">
            <IconButton
              aria-label="clone"
              size="medium"
              tooltip="Clone"
              onClick={() => props.navigate(`/orders/clone`, { state: props.cellValues.row })}
            >
              <ContentCopyIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        )}

      {getOrderStatus(props.cellValues.row).isCanTerminated &&
        (filterUtil.checkPermissionsForEdit(props.cellValues.row, permissions, user.role) ||
          filterUtil.checkPermissionsForApprove(props.cellValues.row, permissions, user.role)) && (
          <Tooltip title="Terminate">
            <IconButton
              aria-label="terminate"
              size="medium"
              onClick={() =>
                dispatch(
                  openDialog({
                    children: (
                      <AlertDialog
                        title="Are you sure want to terminate the order?"
                        okButtonName="Terminate"
                        cancelButtonName="No"
                        handleOk={() => terminateStatus(props.cellValues.row)}
                      />
                    ),
                  })
                )
              }
            >
              <StopCircleOutlinedIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        )}
      {getOrderStatus(props.cellValues.row).isCancelable &&
        (filterUtil.checkPermissionsForEdit(props.cellValues.row, permissions, user.role) ||
          (props.cellValues.row.status === OrderStatus.SCHEDULED.name &&
            !!user.permissions.filter((perm) => perm.permission === 'Approve').length)) && (
          <Tooltip title="Cancel">
            <IconButton
              aria-label="cancel"
              size="medium"
              onClick={() =>
                dispatch(
                  openDialog({
                    children: (
                      <AlertDialog
                        title="Are you sure want to cancel the order?"
                        okButtonName="Yes, Cancel the Order"
                        cancelButtonName="No"
                        handleOk={() => cancelOrder(props.cellValues.row)}
                      />
                    ),
                  })
                )
              }
            >
              <CancelOutlinedIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        )}
    </>
  );
}

export default withRouter(OrdersAction);
