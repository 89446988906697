import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

const pricesAdapter = createEntityAdapter({});

export const { selectAll: selectPrices, selectById: selectPriceById } = pricesAdapter.getSelectors(
  (state) => state.billing.prices
);

const pricesSlice = createSlice({
  name: 'billing/prices',
  initialState: pricesAdapter.getInitialState({
    priceDialog: {
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    openPriceDialog: (state, action) => {
      state.priceDialog = {
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closePriceDialog: (state, action) => {
      state.priceDialog = {
        props: {
          open: false,
        },
        data: null,
      };
    },
  },
});

export const { openPriceDialog, closePriceDialog } = pricesSlice.actions;

export default pricesSlice.reducer;
