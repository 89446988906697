import { createApi } from '@reduxjs/toolkit/query/react';
import { showMessage } from 'app/store/fuse/messageSlice';
import { billingBaseQuery } from '../utils';
import { getCurrentDateStr } from '../../../services/dateUtil';
import { getContractName } from './correctionsApi';

const invalidateCacheHeader = { headers: { 'Cache-Control': 'max-age=0' } };

export const pricesApi = createApi({
  reducerPath: 'pricesApi',
  baseQuery: billingBaseQuery,
  tagTypes: ['Prices', 'AllPrices', 'MnoPrices'],
  endpoints: (builder) => ({
    getAllPrices: builder.query({
      query: (validDate = null) => ({
        url: validDate ? `/prices?validDate=${validDate}` : '/prices',
        method: 'GET',
        headers: invalidateCacheHeader.headers,
      }),
      providesTags: ['AllPrices'],
    }),
    getPricesOfCode: builder.query({
      queryFn: async (codeId, _queryApi, _extraOptions, fetchWithBQ) => {
        try {
          const requests = [
            fetchWithBQ({
              url: `/codes/${codeId}/prices`,
              method: 'GET',
              headers: invalidateCacheHeader.headers,
            }),
            fetchWithBQ({
              url: 'contracts',
              method: 'GET',
            }),
          ];

          const [pricesResult, contractsResult] = await Promise.allSettled(requests);

          if (pricesResult.status === 'rejected') {
            throw pricesResult.reason || new Error('Failed to fetch prices.');
          }

          if (contractsResult.status === 'rejected') {
            throw contractsResult.reason || new Error('Failed to fetch contracts.');
          }

          const prices = pricesResult.value.data;
          const contracts = contractsResult.value.data;

          const transformedData = prices
            .map((price) =>
              Object.fromEntries(
                Object.entries(price).filter(([k, v]) => !!v || !Number.isNaN(v))
              )
            )
            .map((price) => ({
              ...price,
              contractName: getContractName(contracts, price.contractId),
            }));

          return { data: transformedData };
        } catch (error) {
          return { error };
        }
      },
      providesTags: ['Prices'],
    }),
    getMnoValidPrices: builder.query({
      query: ({ mno, validDate = getCurrentDateStr() }) => ({
        url: `/mno/${mno}/prices?validDate=${validDate}`,
        method: 'GET',
        headers: invalidateCacheHeader.headers
      }),
      transformResponse: (response) =>
        response.map((price) =>
          Object.fromEntries(Object.entries(price).filter(([k, v]) => !!v || !Number.isNaN(v)))
        ),
      providesTags: ['MnoPrices'],
    }),
    savePrice: builder.mutation({
      query: (price) => ({
        url: '/prices',
        method: 'PUT',
        body: price,
      }),
      invalidatesTags: ['Prices', 'AllPrices', 'MnoPrices'],
      async onQueryStarted(price, { dispatch, getState, queryFulfilled }) {
        try {
          const selectPricesOfCode = pricesApi.endpoints.getPricesOfCode.select(price.codeId);
          const currentPrices = selectPricesOfCode(getState())?.data || [];

          const startDate = price.validFrom ? Date.parse(price.validFrom) : Date.parse('0000-01-01');
          const endDate = price.validTo ? Date.parse(price.validTo) : Date.parse('9999-01-01');

          for (const element of currentPrices) {
            if (
              price.id !== element.id &&
              startDate <= Date.parse(element.validTo) &&
              endDate > Date.parse(element.validFrom)
            ) {
              dispatch(
                showMessage({
                  message: `Given range overlaps with range ${element.validFrom} to ${element.validTo}!`,
                  variant: 'error',
                })
              );
              return;
            }
          }

          await queryFulfilled;
          dispatch(
            showMessage({
              message: 'Price saved successfully!',
              variant: 'success',
            })
          );
        } catch (error) {
          dispatch(
            showMessage({
              message: 'Price already exists!',
              variant: 'error',
            })
          );
        }
      },
    }),
  }),
});

export const {
  useGetAllPricesQuery,
  useGetPricesOfCodeQuery,
  useGetMnoValidPricesQuery,
  useSavePriceMutation,
} = pricesApi;
