import { useCallback } from 'react';
import { useReactFlow } from 'reactflow';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useDispatch } from 'react-redux';
import { ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material';
import ContentCopy from '@mui/icons-material/ContentCopy';

export default function ContextMenu({ id, top, left, right, bottom, ...props }) {
  const { getNode } = useReactFlow();
  const dispatch = useDispatch();

  const copyContentToClipboard = useCallback(() => {
    const node = getNode(id);

    const writeToClipboard = async (text, message = 'Content copied to clipboard!', variant = 'info') => {
      try {
        await navigator.clipboard.writeText(text);
        dispatch(showMessage({ message, variant }));
      } catch (error) {
        dispatch(
          showMessage({ message: 'Failed to copy content to clipboard!', variant: 'error' })
        );
      }
    };

    const getDisplayText = (content) => {
      return node.data.optionalDisplayText ? `${node.data.optionalDisplayText}|${content}` : content;
    };

    if (node?.data) {
      const { text, message, url, menuTitle, menuItemList } = node.data;
      let content = text || message || url;

      if (menuTitle) {
        content = `${menuTitle} ${menuItemList}`;
      }

      if (content) {
        writeToClipboard(getDisplayText(content));
      } else {
        dispatch(showMessage({ message: 'This content cannot be copied!', variant: 'warning' }));
      }
    } else {
      dispatch(showMessage({ message: 'No valid data to copy!', variant: 'warning' }));
    }
  }, [id, getNode, dispatch]);
  return (
    <div
      style={{ top, left, right, bottom }}
      className="bg-white border-solid shadow-2xl absolute z-10"
      {...props}
    >
      <MenuList>
        <MenuItem onClick={copyContentToClipboard}>
          <ListItemIcon>
            <ContentCopy fontSize="small" />
          </ListItemIcon>
          <ListItemText>Copy to Clipboard</ListItemText>
        </MenuItem>
      </MenuList>
    </div>
  );
}
