import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

const accountsAdapter = createEntityAdapter({});

const accountsSlice = createSlice({
  name: 'billing/accounts',
  initialState: accountsAdapter.getInitialState({
    loading: false,
    accountDialog: {
      type: 'new',
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    openNewAccountDialog: (state, action) => {
      state.accountDialog = {
        type: 'new',
        props: {
          open: true,
        },
        data: null,
      };
    },
    closeNewAccountDialog: (state, action) => {
      state.accountDialog = {
        type: 'new',
        props: {
          open: false,
        },
        data: null,
      };
    },
    openEditAccountDialog: (state, action) => {
      state.accountDialog = {
        type: 'edit',
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeEditAccountDialog: (state, action) => {
      state.accountDialog = {
        type: 'edit',
        props: {
          open: false,
        },
        data: null,
      };
    },
  }
});

export const {
  openNewAccountDialog,
  closeNewAccountDialog,
  openEditAccountDialog,
  closeEditAccountDialog,
} = accountsSlice.actions;

export default accountsSlice.reducer;
