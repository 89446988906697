import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import DialogContent from '@mui/material/DialogContent';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect } from 'react';
import { Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import FormMnoDropdown from '../../../shared-components/FormMnoDropdown';
import { closeNewContractDialog } from '../store/contractsSlice';
import _ from '../../../../@lodash';
import { useSaveContractMutation } from '../store/contractsApi';

const defaultValues = {
  mno: '',
  name: '',
  id: 1,
};

const schema = yup.object().shape({
  mno: yup.string().required('You must select MNO'),
  name: yup.string().required('You must select Name'),
  id: yup.number().min(1, 'Minimum ID value is 1').required('You must select ID'),
});
export default function ContractDialog() {
  const dispatch = useDispatch();
  const contractDialog = useSelector(({ billing }) => billing.contracts.contractDialog);
  const [saveContract] = useSaveContractMutation();

  const { control, reset, handleSubmit, formState } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  const initDialog = useCallback(() => {
    reset({ ...defaultValues, ...contractDialog.data });
  }, [contractDialog.data, reset]);

  useEffect(() => {
    if (contractDialog.props.open) {
      initDialog();
    }
  }, [contractDialog.props.open, initDialog]);

  function onSubmit(payload) {
    saveContract(payload);
    onDialogClose();
  }
  function onDialogClose() {
    dispatch(closeNewContractDialog());
  }
  return (
    <Dialog
      {...contractDialog.props}
      fullWidth
      onClose={onDialogClose}
      onSubmit={handleSubmit(onSubmit)}
    >
      <AppBar position="static" elevation={0}>
        <Toolbar className="flex w-full">
          <Typography variant="subtitle1" color="inherit">
            {_.capitalize(contractDialog.type)} Contract
          </Typography>
        </Toolbar>
      </AppBar>
      <form noValidate className="flex flex-col md:overflow-hidden">
        <DialogContent>
          <Grid container direction="column" rowSpacing={2}>
            <Grid item>
              <Controller
                name="id"
                control={control}
                render={({ field }) => (
                  <TextField
                    disabled={contractDialog.data}
                    {...field}
                    label="ID"
                    fullWidth
                    required
                    type="number"
                  />
                )}
              />
            </Grid>
            <Grid item>
              <FormMnoDropdown
                control={control}
                label="MNO"
                name="mno"
                required
                allmno="true"
                noPermissionFiltering
              />
            </Grid>
            <Grid item>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField {...field} label="NAME" fullWidth required type="text" />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="justify-start px-24 pb-16">
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            disabled={_.isEmpty(dirtyFields) || !isValid}
          >
            { contractDialog.type === 'edit' ? 'Save' : 'Add' }
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={() => dispatch(closeNewContractDialog())}
          >
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
