import { Grid, IconButton, Tooltip } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useDispatch } from 'react-redux';
import AlertDialog from '../../../shared-components/AlertDialog';
import { openDialog } from '../../../store/fuse/dialogSlice';
import { useDeleteCorrectionMutation } from '../store/correctionsApi';

function CorrectionAction(props) {
  const dispatch = useDispatch();
  const [deleteCorrection] = useDeleteCorrectionMutation();

  return (
    <>
      {props.deleteEnabled && (
        <Tooltip title="Delete">
          <IconButton
            aria-label="delete"
            size="medium"
            color="error"
            onClick={() =>
              dispatch(
                openDialog({
                  children: (
                    <AlertDialog
                      title="Confirm Deletion of Correction Record"
                      content={
                        <Grid container spacing={1}>
                          <Grid item xs={4}>
                            <b>MNO:</b>
                          </Grid>
                          <Grid item xs={8}>
                            {props.cellValues.row.mno}
                          </Grid>
                          <Grid item xs={4}>
                            <b>Date Entered:</b>
                          </Grid>
                          <Grid item xs={8}>
                            {props.cellValues.row.dateEntered}
                          </Grid>
                          <Grid item xs={4}>
                            <b>Billing Account:</b>
                          </Grid>
                          <Grid item xs={8}>
                            {props.cellValues.row.billingAccountName}
                          </Grid>
                          <Grid item xs={4}>
                            <b>Amount:</b>
                          </Grid>
                          <Grid item xs={8}>
                            {props.cellValues.row.subscriberAmount}{' '}
                            {props.cellValues.row.subscriberAmountCurrency}
                          </Grid>
                        </Grid>
                      }
                      okButtonName="Yes, delete"
                      cancelButtonName="No"
                      handleOk={() => deleteCorrection(props.cellValues.row.id)}
                      okButtonColor="error"
                    />
                  ),
                })
              )
            }
          >
            <CancelOutlinedIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
}

export default CorrectionAction;
