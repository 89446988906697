import { Card, Divider, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  CALLER_TUNEZ_SERVICE_LABELS,
  CIS_SERVICE_LABELS, DEP_SERVICE_LABELS,
  DSM_SERVICE_LABELS,
  MAD_SERVICE_LABELS,
  SIXD_SERVICE_LABELS,
} from 'app/services/constants';
import { isFieldRequired } from '../../main/orders/order/orderHelper';
import { validateRemoteAPI } from '../../main/orders/order/journey/forms/ServiceApiCallForms/utils';

function JourneyContent({ data, stepType }) {
  const JOURNEY_STEP_DESCRIPTION = 'Click here to enter details';
  const [serviceInfo, setServiceInfo] = useState('');
  const isDsmCall = data.name === 'DsmServiceCall';
  const is6DCall = data.name === '6DServiceCall';
  const isMadCall = data.name === 'MadServiceCall';
  const isCisCall = data.name === 'CisServiceCall';
  const isCallerTunezCall = data.name === 'CallerTunezCall';
  const isDepCall = data.name && data.name.toLowerCase().startsWith('dep');

  const fetchServiceInfo = async (serviceId) => {
    const result = await validateRemoteAPI(data.name, serviceId);
    setServiceInfo(`Service Id: ${serviceId} \n  ${result.description}`);
  };

  useEffect(() => {
    // No need to call DSM service again, data is ready due to DOI.
    if (!isDsmCall && data.variables && data.variables.serviceId) {
      fetchServiceInfo(data.variables.serviceId);
    }
  }, [data.variables && data.variables.serviceId]);

  return (
    <Card className="w-full rounded-10 shadow p-10" style={{ maxHeight: '300px' }}>
      {data.optionalDisplayText && (
        <Grid item xs={12}>
          <Typography variant="body1" style={{ whiteSpace: 'pre-line', alignItems: 'center' }}>
            {data.optionalDisplayText}
          </Typography>
          <Divider />
        </Grid>
      )}

      {data.text && (
        <Grid item xs={12}>
          <Typography variant="body1" style={{ whiteSpace: 'pre-line', alignItems: 'center' }}>
            {data.text}
          </Typography>
        </Grid>
      )}

      {!data.text && isFieldRequired(stepType, 'text') && (
        <Grid item xs={12}>
          <Typography variant="body1" style={{ color: 'red' }}>
            {JOURNEY_STEP_DESCRIPTION}
          </Typography>
        </Grid>
      )}

      {data.url && (
        <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
          {data.url}
        </Typography>
      )}

      {!data.url && isFieldRequired(stepType, 'url') && (
        <Typography variant="body1" style={{ color: 'red' }}>
          {JOURNEY_STEP_DESCRIPTION}
        </Typography>
      )}

      {data.ussd && (
        <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
          {data.ussd}
        </Typography>
      )}

      {!data.ussd && isFieldRequired(stepType, 'ussd') && (
        <Typography variant="body1" style={{ color: 'red' }}>
          {JOURNEY_STEP_DESCRIPTION}
        </Typography>
      )}

      {data.name && (
        <Grid item>
          <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
            {data.name}
          </Typography>
        </Grid>
      )}

      {data.menuTitle && (
        <Grid item xs={12}>
          <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
            {data.menuTitle}
          </Typography>
          <Divider />
        </Grid>
      )}

      <Grid container>
        {data.menuItemList &&
          data.menuItemList.map((menu) => (
            <Grid key={menu} container className="align-middle" spacing={1}>
              <Grid item xs={12}>
                <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
                  {menu}
                </Typography>
              </Grid>
            </Grid>
          ))}
      </Grid>

      {(!data.menuTitle || !data.menuItemList) &&
        (isFieldRequired(stepType, 'menuTitle') || isFieldRequired(stepType, 'menuItemList')) && (
          <Grid item xs={12}>
            <Typography variant="body1" style={{ color: 'red' }}>
              {JOURNEY_STEP_DESCRIPTION}
            </Typography>
            <Divider />
          </Grid>
        )}

      {data.variables && (
        <div>
          <Divider />
          <Grid container>
            {Object.entries(data.variables).map(([key, value]) => (
              <Grid key={key} container className="align-middle" spacing={1}>
                <Grid item xs={12}>
                  {isDsmCall && value && (
                    <Typography component="div">
                      {DSM_SERVICE_LABELS[key]}: {value}
                    </Typography>
                  )}
                  {is6DCall && value && (
                    <Typography component="div">
                      {SIXD_SERVICE_LABELS[key]}: {value}
                    </Typography>
                  )}
                  {isMadCall && value && MAD_SERVICE_LABELS[key] && (
                    <Typography component="div">
                      {MAD_SERVICE_LABELS[key]}: {value}
                    </Typography>
                  )}
                  {isCisCall && value && (
                    <Typography component="div">
                      {CIS_SERVICE_LABELS[key]}: {value}
                    </Typography>
                  )}
                  {isCallerTunezCall && value && (
                    <Typography component="div">
                      {CALLER_TUNEZ_SERVICE_LABELS[key]}: {value}
                    </Typography>
                  )}
                  {isDepCall && value && (
                    <Typography component="div">
                      {DEP_SERVICE_LABELS[key]}: {value}
                    </Typography>
                  )}
                  {!value && !data.readOnly && isFieldRequired(stepType, `variables.${key}`) && (
                    <Typography style={{ color: 'red' }}>{JOURNEY_STEP_DESCRIPTION}</Typography>
                  )}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </div>
      )}

      {data.message && (
        <Grid item>
          <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
            {data.message}
          </Typography>
        </Grid>
      )}

      {!data.message && isFieldRequired(stepType, 'message') && (
        <Grid item>
          <Typography variant="body1" style={{ color: 'red', whiteSpace: 'pre-line' }}>
            {JOURNEY_STEP_DESCRIPTION}
          </Typography>
        </Grid>
      )}

      {data.destinationAddress && data.destinationAddress.addressString && (
        <Grid item>
          <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
            {data.destinationAddress.addressString || JOURNEY_STEP_DESCRIPTION}
          </Typography>
        </Grid>
      )}

      {data.originatingAddress && data.originatingAddress.addressString && (
        <Grid item>
          <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
            {data.originatingAddress.addressString || JOURNEY_STEP_DESCRIPTION}
          </Typography>
        </Grid>
      )}
    </Card>
  );
}
export default JourneyContent;
