import { combineReducers } from '@reduxjs/toolkit';
import orders from './ordersSlice';
import order from './orderSlice';
import subscriberActivity from './subscriberActivitySlice';
import downloadMsisdnList from './downloadMsisdnListSlice';
import journeyOrderEvents from './orderEventsSlice';
import receivedUserNotifications from './receivedNotificationsSlice';
import payment from './paymentSlice';
import utility from './utilitySlice';

const reducer = combineReducers({
  orders,
  order,
  subscriberActivity,
  downloadMsisdnList,
  journeyOrderEvents,
  receivedUserNotifications,
  payment,
  utility,
});

export default reducer;
