import { Link } from 'react-router-dom';
import { Button, Icon, Typography } from '@mui/material';
import _ from '@lodash';
import { useFormContext } from 'react-hook-form';
import { motion } from 'framer-motion';
import BillingSpinner from '../BillingSpinner';
import { useGetCodesQuery, useSaveCodeMutation } from '../store/codesApi';

function CodeHeader({ isBillingAdmin }) {
  const methods = useFormContext();

  const { formState, watch, getValues } = methods;
  const { isValid, dirtyFields } = formState;
  const name = watch('name');
  const [saveCode] = useSaveCodeMutation();

  function saveBillingCode() {
    return saveCode(getValues());
  }

  return (
    <div className="flex flex-1 wrap w-full items-center justify-between">
      <div className="flex grow items-center gap-20">
        <Link to="/billing-admin/codes" className="flex flex-row items-center gap-2">
          <Icon className="text-20">arrow_back</Icon>
          <span className="hidden sm:flex font-small">Codes</span>
        </Link>
        <div className="flex flex-row items-center">
          <Icon
            component={motion.span}
            initial={{ scale: 0 }}
            animate={{ scale: 1, transition: { delay: 0.2 } }}
            className="text-24 md:text-32"
          >
            code
          </Icon>
          <Typography
            component={motion.span}
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="sm:flex text-16 md:text-24 mx-12 font-semibold"
          >
            {name || 'New Code'}
          </Typography>
        </div>
      </div>

      {isBillingAdmin && (
        <div className="flex">
          <Button
            variant="contained"
            color="secondary"
            disabled={_.isEmpty(dirtyFields) || !isValid}
            onClick={() => saveBillingCode()}
          >
            Save
          </Button>
        </div>
      )}
      <BillingSpinner />
    </div>
  );
}

export default CodeHeader;
