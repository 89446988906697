import { StepEdge, useNodes } from 'reactflow';
import {
  getSmartEdge,
  pathfindingAStarDiagonal,
  SmartStepEdge,
  svgDrawStraightLinePath,
} from '@tisoap/react-flow-smart-edge';
import { Button, Popover, Typography } from '@mui/material';
import { useState, useMemo } from 'react';

const CustomStepConfiguration = {
  drawEdge: svgDrawStraightLinePath,
  generatePath: pathfindingAStarDiagonal,
  fallback: StepEdge,
  nodePadding: 50,
};

const CustomSmartBezierButtonEdge = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const nodes = useNodes();

  const {
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style,
    markerEnd,
    markerStart,
    label,
  } = props;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const getSmartEdgeResponse = useMemo(
    () =>
      getSmartEdge({
        sourceX,
        sourceY,
        targetX,
        targetY,
        sourcePosition,
        targetPosition,
        nodes,
        options: CustomStepConfiguration,
      }),
    [sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, nodes]
  );

  if (!getSmartEdgeResponse) {
    return <SmartStepEdge {...props} />;
  }

  const { svgPathString, edgeCenterX, edgeCenterY } = getSmartEdgeResponse;

  return (
    <>
      <path
        d={svgPathString}
        style={style}
        className="react-flow__edge-path"
        markerEnd={markerEnd}
        markerStart={markerStart}
      />

      {label && (
        <foreignObject x={edgeCenterX - 50} y={edgeCenterY - 25} width={100} height={50}>
          {label.length > 30 ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                aria-describedby={id}
                color="primary"
                variant="contained"
                size="small"
                onClick={handleClick}
              >
                Show Condition
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
              >
                <Typography sx={{ p: 2 }}>{label}</Typography>
              </Popover>
            </div>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center' }}>{label}</div>
          )}
        </foreignObject>
      )}
    </>
  );
};

export default CustomSmartBezierButtonEdge;
