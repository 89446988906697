import { createApi } from '@reduxjs/toolkit/query/react';
import { graphqlBaseQuery } from '../../../../graphql/base-query';
import * as queries from '../../../../graphql/queries';
import * as mutations from '../../../../graphql/mutations';
import { showMessage } from '../../../store/fuse/messageSlice';
import ComponentLogger from 'app/services/logger/ComponentLogger';

const logger = new ComponentLogger('JourneyApi');

export const journeysApi = createApi({
  reducerPath: 'journeysApi',
  baseQuery: graphqlBaseQuery,
  tagTypes: ['Journeys'],
  endpoints: (builder) => ({
    getJourneys: builder.query({
      query: () => ({
        query: queries.listJourneys,
        variables: {},
      }),
      transformResponse: (response) => response.listJourneys.items,
      providesTags: ['Journeys'],
    }),
    saveJourney: builder.mutation({
      async queryFn(journey, { dispatch }) {
        try {
          // Validate the journey
          const validationResponse = await graphqlBaseQuery({
            query: queries.validateJourney,
            variables: { journey: JSON.stringify(journey) },
          });

          if (validationResponse.error) {
            const errorDescription = validationResponse.error.data || 'Validation failed';
            dispatch(
              showMessage({
                message: `Validation failed: ${errorDescription}`,
                variant: 'error',
              })
            );
            return { error: errorDescription };
          }

          const isUpdate = !!journey.id;
          const mutation = isUpdate ? mutations.updateJourney : mutations.createJourney;

          const { createdAt, updatedAt, version, _version, _deleted, _lastChangedAt, ...input } =
            journey;

          const response = await graphqlBaseQuery({
            query: mutation,
            variables: { input },
          });

          if (response.error) {
            logger.error(isUpdate ? 'updateJourney' : 'createJourney', {
              data: journey,
              error: response.error,
            });
            return { error: response.error };
          }

          dispatch(
            showMessage({
              message: isUpdate
                ? 'Journey has been updated successfully!'
                : 'Journey has been created successfully!',
              variant: 'success',
            })
          );

          return { data: response.data[isUpdate ? 'updateJourney' : 'createJourney'] };
        } catch (error) {
          logger.error('saveJourney', { journey, error });
          return { error: 'Failed to save the journey.' };
        }
      },
      invalidatesTags: ['Journeys'],
    }),
  }),
});

export const { useGetJourneysQuery, useSaveJourneyMutation } = journeysApi;
