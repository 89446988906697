/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listSubscriberActivities = /* GraphQL */ `
  query ListSubscriberActivities(
    $mno: String
    $queryType: String
    $queryParam: String
    $billingAccount: [String]
    $campaignTemplate: [String]
    $billingCode: [String]
    $startDate: String
    $endDate: String
  ) {
    listSubscriberActivities(
      mno: $mno
      queryType: $queryType
      queryParam: $queryParam
      billingAccount: $billingAccount
      campaignTemplate: $campaignTemplate
      billingCode: $billingCode
      startDate: $startDate
      endDate: $endDate
    ) {
      dateTime
      orderName
      stepName
      stepType
      result
      resultDetail
      tag
      orderId
      executionId
      mno
    }
  }
`;
export const validateAPI = /* GraphQL */ `
  query ValidateAPI($api: String, $serviceId: String) {
    validateAPI(api: $api, serviceId: $serviceId) {
      statusCode
      description
      data
    }
  }
`;
export const validateJourney = /* GraphQL */ `
  query ValidateJourney($journey: AWSJSON) {
    validateJourney(journey: $journey) {
      statusCode
      description
      data
    }
  }
`;
export const getCampaignMsisdnList = /* GraphQL */ `
  query GetCampaignMsisdnList(
    $mno: String
    $orderId: String
    $orderExecutionId: String
    $tag: String
    $identityId: String
    $fileName: String
  ) {
    getCampaignMsisdnList(
      mno: $mno
      orderId: $orderId
      orderExecutionId: $orderExecutionId
      tag: $tag
      identityId: $identityId
      fileName: $fileName
    )
  }
`;
export const getQuickSightEmbedUrl = /* GraphQL */ `
  query GetQuickSightEmbedUrl($dashboardId: String, $tags: AWSJSON) {
    getQuickSightEmbedUrl(dashboardId: $dashboardId, tags: $tags)
  }
`;
export const refreshUbsDataset = /* GraphQL */ `
  query RefreshUbsDataset($datasets: [String]) {
    refreshUbsDataset(datasets: $datasets)
  }
`;
export const listIngestions = /* GraphQL */ `
  query ListIngestions($datasets: [String], $limit: Int) {
    listIngestions(datasets: $datasets, limit: $limit) {
      status
      startTime
      duration
      ingestionId
      datasetId
    }
  }
`;
export const listIncompletePayments = /* GraphQL */ `
  query ListIncompletePayments(
    $isAdmin: Boolean
    $billingAccountIdParameter: String
  ) {
    listIncompletePayments(
      isAdmin: $isAdmin
      billingAccountIdParameter: $billingAccountIdParameter
    ) {
      id
      amountFulfilled
      amountRequested
      billingAccountId
      channel
      channelTransactionId
      createdAt
      description
      email
      fee
      feeBearer
      mnoId
      paymentDate
      paymentInstrument
      phone
      refunds {
        amount
        createdAt
        description
        paymentRecordId
        refundDate
      }
      responseCode
      responseMessage
      signature
      timestamp
      title
      transactionId
      updatedAt
    }
  }
`;
export const utility = /* GraphQL */ `
  query Utility($action: String!, $data: AWSJSON) {
    utility(action: $action, data: $data) {
      statusCode
      result
    }
  }
`;
export const listJourneys = /* GraphQL */ `
  query ListJourneys(
    $filter: ModelJourneyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJourneys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        steps
        transitions {
          originatingStep
          destinationStep
          condition
          responseText
          task
          hidden
        }
        tags
        billing
        metrics
        edr {
          providerId
          contentProvider
        }
        isDisabled
        reporting {
          introduction_text
          confirmation_text
          fulfillment_details
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listJourneyOrderEvents = /* GraphQL */ `
  query ListJourneyOrderEvents(
    $journeyOrderId: String
    $eventId: ModelStringKeyConditionInput
    $filter: ModelJourneyOrderEventFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listJourneyOrderEvents(
      journeyOrderId: $journeyOrderId
      eventId: $eventId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        journeyOrderId
        eventId
        eventType
        eventOwner
        description
        details
        eventDate
        createdAt
        updatedAt
        version
      }
      nextToken
    }
  }
`;
export const listLogRecords = /* GraphQL */ `
  query ListLogRecords(
    $username: String
    $date: ModelStringKeyConditionInput
    $filter: ModelFlightdeckRecordLogFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLogRecords(
      username: $username
      date: $date
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        username
        date
        eventOwner
        eventType
        eventModel
        changeLog
        oldRecord
        newRecord
        createdAt
        updatedAt
        version
      }
      nextToken
    }
  }
`;
export const getJourneyTemplate = /* GraphQL */ `
  query GetJourneyTemplate($id: ID!) {
    getJourneyTemplate(id: $id) {
      id
      name
      mno
      product
      journeyID
      journey {
        id
        name
        steps
        transitions {
          originatingStep
          destinationStep
          condition
          responseText
          task
          hidden
        }
        tags
        billing
        metrics
        edr {
          providerId
          contentProvider
        }
        isDisabled
        reporting {
          introduction_text
          confirmation_text
          fulfillment_details
        }
        createdAt
        updatedAt
      }
      isDisabled
      variables {
        path
        value
      }
      createdAt
      updatedAt
    }
  }
`;
export const listJourneyTemplates = /* GraphQL */ `
  query ListJourneyTemplates(
    $filter: ModelJourneyTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJourneyTemplates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        mno
        product
        journeyID
        journey {
          id
          name
          steps
          transitions {
            originatingStep
            destinationStep
            condition
            responseText
            task
            hidden
          }
          tags
          billing
          metrics
          edr {
            providerId
            contentProvider
          }
          isDisabled
          reporting {
            introduction_text
            confirmation_text
            fulfillment_details
          }
          createdAt
          updatedAt
        }
        isDisabled
        variables {
          path
          value
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPermission = /* GraphQL */ `
  query GetPermission($username: String!, $permission: String!) {
    getPermission(username: $username, permission: $permission) {
      username
      permission
      productFamily
      mno
      billingAccount
      product
      campaignTemplate
      billingCode
      updaterId
      createdAt
      updatedAt
    }
  }
`;
export const listPermissions = /* GraphQL */ `
  query ListPermissions(
    $username: String
    $permission: ModelStringKeyConditionInput
    $filter: ModelPermissionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPermissions(
      username: $username
      permission: $permission
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        username
        permission
        productFamily
        mno
        billingAccount
        product
        campaignTemplate
        billingCode
        updaterId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserSetting = /* GraphQL */ `
  query GetUserSetting($username: String!) {
    getUserSetting(username: $username) {
      username
      msisdnSourceSetting {
        queryEnabled
        fileUploadEnabled
      }
      executionFlowStatsEnabled
      downloadAllMSISDNListsEnabled
      downloadSuccessMSISDNListsEnabled
      searchMSISDNActivity
      orderExecutionWithTime
      viewMNOBillingReport
      viewResellerBillingReport
      viewProvisionedSubscribersReport
      deviceTypeTargeting
      paymentFunctionalityEnabled
      updaterId
      createdAt
      updatedAt
    }
  }
`;
export const listUserSettings = /* GraphQL */ `
  query ListUserSettings(
    $username: String
    $filter: ModelUserSettingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserSettings(
      username: $username
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        username
        msisdnSourceSetting {
          queryEnabled
          fileUploadEnabled
        }
        executionFlowStatsEnabled
        downloadAllMSISDNListsEnabled
        downloadSuccessMSISDNListsEnabled
        searchMSISDNActivity
        orderExecutionWithTime
        viewMNOBillingReport
        viewResellerBillingReport
        viewProvisionedSubscribersReport
        deviceTypeTargeting
        paymentFunctionalityEnabled
        updaterId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listSingleMsisdnOrderExecutions = /* GraphQL */ `
  query ListSingleMsisdnOrderExecutions(
    $journeyOrderId: String
    $requestDate: ModelStringKeyConditionInput
    $filter: ModelSingleMsisdnOrderExecutionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSingleMsisdnOrderExecutions(
      journeyOrderId: $journeyOrderId
      requestDate: $requestDate
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        journeyOrderId
        requestDate
        mno
        msisdn
        status
        executionId
        description
      }
      nextToken
    }
  }
`;
export const getUserNotification = /* GraphQL */ `
  query GetUserNotification($username: String!) {
    getUserNotification(username: $username) {
      username
      orderApprovalIsRequiredByMe
      orderApprovedByMeCanceledOrTerminated
      myOrderIsApprovedOrRejected
      myOrderIsCancelledOrTerminated
      myOrderIsCompleted
      createdAt
      updatedAt
    }
  }
`;
export const listUserNotifications = /* GraphQL */ `
  query ListUserNotifications(
    $username: String
    $filter: ModelUserNotificationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserNotifications(
      username: $username
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        username
        orderApprovalIsRequiredByMe
        orderApprovedByMeCanceledOrTerminated
        myOrderIsApprovedOrRejected
        myOrderIsCancelledOrTerminated
        myOrderIsCompleted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listReceivedUserNotifications = /* GraphQL */ `
  query ListReceivedUserNotifications(
    $username: String
    $notificationDate: ModelStringKeyConditionInput
    $filter: ModelReceivedUserNotificationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listReceivedUserNotifications(
      username: $username
      notificationDate: $notificationDate
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        username
        notificationDate
        readDate
        orderId
        orderName
        description
        status
        eventOwner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMTNSAProviderID = /* GraphQL */ `
  query GetMTNSAProviderID($billingAccountId: String!) {
    getMTNSAProviderID(billingAccountId: $billingAccountId) {
      billingAccountId
      providerIds
      createdAt
      updatedAt
    }
  }
`;
export const listMTNSAProviderIDs = /* GraphQL */ `
  query ListMTNSAProviderIDs(
    $billingAccountId: String
    $filter: ModelMTNSAProviderIDFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMTNSAProviderIDs(
      billingAccountId: $billingAccountId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        billingAccountId
        providerIds
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPolicyLog = /* GraphQL */ `
  query GetPolicyLog($username: String!) {
    getPolicyLog(username: $username) {
      username
      approvedVersion
      createdAt
      updatedAt
    }
  }
`;
export const listFlightdeckApiRequests = /* GraphQL */ `
  query ListFlightdeckApiRequests(
    $msisdn: String
    $eventId: ModelStringKeyConditionInput
    $filter: ModelFlightdeckAPIRequestFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFlightdeckApiRequests(
      msisdn: $msisdn
      eventId: $eventId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        msisdn
        eventId
        creationDate
        journeyExecutionId
        journeyOrderId
        mno
        stepName
        result
        subscriberId
        requestPayload
        responsePayload
        callbackPayload
        ttl
      }
      nextToken
    }
  }
`;
export const orderByMnoAndStatus = /* GraphQL */ `
  query OrderByMnoAndStatus(
    $mno: String!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSingleMsisdnOrderExecutionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orderByMnoAndStatus(
      mno: $mno
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        journeyOrderId
        requestDate
        mno
        msisdn
        status
        executionId
        description
      }
      nextToken
    }
  }
`;
export const listFlightdeckApiRequestsByJourneyOrderIdAndCreationDate = /* GraphQL */ `
  query ListFlightdeckApiRequestsByJourneyOrderIdAndCreationDate(
    $journeyOrderId: String!
    $creationDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFlightdeckAPIRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFlightdeckApiRequestsByJourneyOrderIdAndCreationDate(
      journeyOrderId: $journeyOrderId
      creationDate: $creationDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        msisdn
        eventId
        creationDate
        journeyExecutionId
        journeyOrderId
        mno
        stepName
        result
        subscriberId
        requestPayload
        responsePayload
        callbackPayload
        ttl
      }
      nextToken
    }
  }
`;
export const getJourneyOrder = /* GraphQL */ `
  query GetJourneyOrder($id: ID!) {
    getJourneyOrder(id: $id) {
      id
      definition {
        steps
        transitions {
          originatingStep
          destinationStep
          condition
          responseText
          task
        }
        billing
        metrics
        tags
        edr {
          providerId
          contentProvider
        }
        isDisabled
        reporting {
          introduction_text
          confirmation_text
          fulfillment_details
        }
      }
      executionStatuses {
        items {
          id
          journeyOrderId
          journeyOrder {
            id
            definition {
              steps
              billing
              metrics
              tags
              isDisabled
            }
            executionStatuses {
              nextToken
            }
            name
            billingCodeId
            billingPremiumCodeId
            mno
            maxPrice
            bidPrice
            maxBudget
            targetRequested
            targetExcluded
            authorizedAmount
            handsetsToTarget
            targetable
            targeted
            exclusionListUri
            status
            validFrom
            validTo
            startTime
            endTime
            executionTimeRestrictionEnabled
            excludedDays
            targetMsisdnListUris
            templateId
            taxonomy
            product
            billingAccountId
            description
            updaterId
            ownerId
            approverId
            createdAt
            updatedAt
            version
            lastTransmittedDate
          }
          priceExecuted
          rejected
          terminated
          executionDatetime
          targetingStartDateTime
          targetingEndDateTime
          steps
          metrics {
            targeted
            reached
            engagements
            optedOut
            success
            interest
            confirmed
          }
          createdAt
          updatedAt
          version
          singleMsisdn
        }
        nextToken
      }
      name
      billingCodeId
      billingPremiumCodeId
      mno
      maxPrice
      bidPrice
      maxBudget
      targetRequested
      targetExcluded
      authorizedAmount
      handsetsToTarget
      targetable
      targeted
      exclusionListUri
      status
      validFrom
      validTo
      startTime
      endTime
      executionTimeRestrictionEnabled
      excludedDays
      targetMsisdnListUris
      templateId
      taxonomy
      product
      billingAccountId
      description
      updaterId
      ownerId
      approverId
      createdAt
      updatedAt
      version
      lastTransmittedDate
    }
  }
`;
export const listJourneyOrders = /* GraphQL */ `
  query ListJourneyOrders(
    $filter: ModelJourneyOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJourneyOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        definition {
          steps
          transitions {
            originatingStep
            destinationStep
            condition
            responseText
            task
          }
          billing
          metrics
          tags
          edr {
            providerId
            contentProvider
          }
          isDisabled
          reporting {
            introduction_text
            confirmation_text
            fulfillment_details
          }
        }
        executionStatuses {
          items {
            id
            journeyOrderId
            journeyOrder {
              id
              name
              billingCodeId
              billingPremiumCodeId
              mno
              maxPrice
              bidPrice
              maxBudget
              targetRequested
              targetExcluded
              authorizedAmount
              handsetsToTarget
              targetable
              targeted
              exclusionListUri
              status
              validFrom
              validTo
              startTime
              endTime
              executionTimeRestrictionEnabled
              excludedDays
              targetMsisdnListUris
              templateId
              taxonomy
              product
              billingAccountId
              description
              updaterId
              ownerId
              approverId
              createdAt
              updatedAt
              version
              lastTransmittedDate
            }
            priceExecuted
            rejected
            terminated
            executionDatetime
            targetingStartDateTime
            targetingEndDateTime
            steps
            metrics {
              targeted
              reached
              engagements
              optedOut
              success
              interest
              confirmed
            }
            createdAt
            updatedAt
            version
            singleMsisdn
          }
          nextToken
        }
        name
        billingCodeId
        billingPremiumCodeId
        mno
        maxPrice
        bidPrice
        maxBudget
        targetRequested
        targetExcluded
        authorizedAmount
        handsetsToTarget
        targetable
        targeted
        exclusionListUri
        status
        validFrom
        validTo
        startTime
        endTime
        executionTimeRestrictionEnabled
        excludedDays
        targetMsisdnListUris
        templateId
        taxonomy
        product
        billingAccountId
        description
        updaterId
        ownerId
        approverId
        createdAt
        updatedAt
        version
        lastTransmittedDate
      }
      nextToken
    }
  }
`;
export const orderByMnoAndValidTo = /* GraphQL */ `
  query OrderByMnoAndValidTo(
    $mno: String!
    $validTo: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJourneyOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orderByMnoAndValidTo(
      mno: $mno
      validTo: $validTo
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        definition {
          steps
          transitions {
            originatingStep
            destinationStep
            condition
            responseText
            task
          }
          billing
          metrics
          tags
          edr {
            providerId
            contentProvider
          }
          isDisabled
          reporting {
            introduction_text
            confirmation_text
            fulfillment_details
          }
        }
        executionStatuses {
          items {
            id
            journeyOrderId
            journeyOrder {
              id
              name
              billingCodeId
              billingPremiumCodeId
              mno
              maxPrice
              bidPrice
              maxBudget
              targetRequested
              targetExcluded
              authorizedAmount
              handsetsToTarget
              targetable
              targeted
              exclusionListUri
              status
              validFrom
              validTo
              startTime
              endTime
              executionTimeRestrictionEnabled
              excludedDays
              targetMsisdnListUris
              templateId
              taxonomy
              product
              billingAccountId
              description
              updaterId
              ownerId
              approverId
              createdAt
              updatedAt
              version
              lastTransmittedDate
            }
            priceExecuted
            rejected
            terminated
            executionDatetime
            targetingStartDateTime
            targetingEndDateTime
            steps
            metrics {
              targeted
              reached
              engagements
              optedOut
              success
              interest
              confirmed
            }
            createdAt
            updatedAt
            version
            singleMsisdn
          }
          nextToken
        }
        name
        billingCodeId
        billingPremiumCodeId
        mno
        maxPrice
        bidPrice
        maxBudget
        targetRequested
        targetExcluded
        authorizedAmount
        handsetsToTarget
        targetable
        targeted
        exclusionListUri
        status
        validFrom
        validTo
        startTime
        endTime
        executionTimeRestrictionEnabled
        excludedDays
        targetMsisdnListUris
        templateId
        taxonomy
        product
        billingAccountId
        description
        updaterId
        ownerId
        approverId
        createdAt
        updatedAt
        version
        lastTransmittedDate
      }
      nextToken
    }
  }
`;
export const searchJourneyOrders = /* GraphQL */ `
  query SearchJourneyOrders(
    $filter: SearchableJourneyOrderFilterInput
    $sort: [SearchableJourneyOrderSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableJourneyOrderAggregationInput]
  ) {
    searchJourneyOrders(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        definition {
          steps
          transitions {
            originatingStep
            destinationStep
            condition
            responseText
            task
          }
          billing
          metrics
          tags
          edr {
            providerId
            contentProvider
          }
          isDisabled
          reporting {
            introduction_text
            confirmation_text
            fulfillment_details
          }
        }
        executionStatuses {
          items {
            id
            journeyOrderId
            journeyOrder {
              id
              name
              billingCodeId
              billingPremiumCodeId
              mno
              maxPrice
              bidPrice
              maxBudget
              targetRequested
              targetExcluded
              authorizedAmount
              handsetsToTarget
              targetable
              targeted
              exclusionListUri
              status
              validFrom
              validTo
              startTime
              endTime
              executionTimeRestrictionEnabled
              excludedDays
              targetMsisdnListUris
              templateId
              taxonomy
              product
              billingAccountId
              description
              updaterId
              ownerId
              approverId
              createdAt
              updatedAt
              version
              lastTransmittedDate
            }
            priceExecuted
            rejected
            terminated
            executionDatetime
            targetingStartDateTime
            targetingEndDateTime
            steps
            metrics {
              targeted
              reached
              engagements
              optedOut
              success
              interest
              confirmed
            }
            createdAt
            updatedAt
            version
            singleMsisdn
          }
          nextToken
        }
        name
        billingCodeId
        billingPremiumCodeId
        mno
        maxPrice
        bidPrice
        maxBudget
        targetRequested
        targetExcluded
        authorizedAmount
        handsetsToTarget
        targetable
        targeted
        exclusionListUri
        status
        validFrom
        validTo
        startTime
        endTime
        executionTimeRestrictionEnabled
        excludedDays
        targetMsisdnListUris
        templateId
        taxonomy
        product
        billingAccountId
        description
        updaterId
        ownerId
        approverId
        createdAt
        updatedAt
        version
        lastTransmittedDate
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const listJourneyExecutionStatuses = /* GraphQL */ `
  query ListJourneyExecutionStatuses(
    $filter: ModelJourneyExecutionStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJourneyExecutionStatuses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        journeyOrderId
        journeyOrder {
          id
          definition {
            steps
            transitions {
              originatingStep
              destinationStep
              condition
              responseText
              task
            }
            billing
            metrics
            tags
            edr {
              providerId
              contentProvider
            }
            isDisabled
            reporting {
              introduction_text
              confirmation_text
              fulfillment_details
            }
          }
          executionStatuses {
            items {
              id
              journeyOrderId
              priceExecuted
              rejected
              terminated
              executionDatetime
              targetingStartDateTime
              targetingEndDateTime
              steps
              createdAt
              updatedAt
              version
              singleMsisdn
            }
            nextToken
          }
          name
          billingCodeId
          billingPremiumCodeId
          mno
          maxPrice
          bidPrice
          maxBudget
          targetRequested
          targetExcluded
          authorizedAmount
          handsetsToTarget
          targetable
          targeted
          exclusionListUri
          status
          validFrom
          validTo
          startTime
          endTime
          executionTimeRestrictionEnabled
          excludedDays
          targetMsisdnListUris
          templateId
          taxonomy
          product
          billingAccountId
          description
          updaterId
          ownerId
          approverId
          createdAt
          updatedAt
          version
          lastTransmittedDate
        }
        priceExecuted
        rejected
        terminated
        executionDatetime
        targetingStartDateTime
        targetingEndDateTime
        steps
        metrics {
          targeted
          reached
          engagements
          optedOut
          success
          interest
          confirmed
        }
        createdAt
        updatedAt
        version
        singleMsisdn
      }
      nextToken
    }
  }
`;
export const journeyExecutionStatusByOrderId = /* GraphQL */ `
  query JourneyExecutionStatusByOrderId(
    $journeyOrderId: ID!
    $executionDatetime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJourneyExecutionStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    journeyExecutionStatusByOrderId(
      journeyOrderId: $journeyOrderId
      executionDatetime: $executionDatetime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        journeyOrderId
        journeyOrder {
          id
          definition {
            steps
            transitions {
              originatingStep
              destinationStep
              condition
              responseText
              task
            }
            billing
            metrics
            tags
            edr {
              providerId
              contentProvider
            }
            isDisabled
            reporting {
              introduction_text
              confirmation_text
              fulfillment_details
            }
          }
          executionStatuses {
            items {
              id
              journeyOrderId
              priceExecuted
              rejected
              terminated
              executionDatetime
              targetingStartDateTime
              targetingEndDateTime
              steps
              createdAt
              updatedAt
              version
              singleMsisdn
            }
            nextToken
          }
          name
          billingCodeId
          billingPremiumCodeId
          mno
          maxPrice
          bidPrice
          maxBudget
          targetRequested
          targetExcluded
          authorizedAmount
          handsetsToTarget
          targetable
          targeted
          exclusionListUri
          status
          validFrom
          validTo
          startTime
          endTime
          executionTimeRestrictionEnabled
          excludedDays
          targetMsisdnListUris
          templateId
          taxonomy
          product
          billingAccountId
          description
          updaterId
          ownerId
          approverId
          createdAt
          updatedAt
          version
          lastTransmittedDate
        }
        priceExecuted
        rejected
        terminated
        executionDatetime
        targetingStartDateTime
        targetingEndDateTime
        steps
        metrics {
          targeted
          reached
          engagements
          optedOut
          success
          interest
          confirmed
        }
        createdAt
        updatedAt
        version
        singleMsisdn
      }
      nextToken
    }
  }
`;
export const listAttributes = /* GraphQL */ `
  query ListAttributes(
    $filter: ModelAttributeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAttributes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        value
        type
        user
        updaterId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
