import FusePageCarded from '@fuse/core/FusePageCarded';
import withReducer from 'app/store/withReducer';
import { styled } from '@mui/material/styles';
import CustomDataGrid from 'app/shared-components/CustomDataGrid';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import reducer from '../store';
import {
  getPlatforms,
  selectPlatforms,
  openEditPlatformDialog,
  openNewPlatformDialog,
} from '../store/platformsSlice';
import PlatformDialog from './PlatformDialog';
import FuseLoading from '../../../../@fuse/core/FuseLoading';
import PageHeader from '../../../shared-components/PageHeader';
import BillingSpinner from '../BillingSpinner';
import { hasUserSupportRole } from '../../../services/permissionUtil';
import { filterByMnoStatus } from '../utils';
import { useGetMnosQuery } from '../../support/store/mnosApi';

const Root = styled(FusePageCarded)(({ theme }) => ({
  '& .FusePageCarded-header': {
    minHeight: 72,
    height: 72,
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      minHeight: 136,
      height: 136,
    },
  },
  '& .FusePageCarded-content': {
    display: 'flex',
  },
  '& .FusePageCarded-contentCard': {
    overflow: 'hidden',
  },
}));

const columns = [
  { field: 'id', headerName: 'ID', width: 50 },
  { field: 'name', headerName: 'Name', flex: 1 },
  { field: 'mno', headerName: 'MNO', flex: 1 },
];

function Platforms() {
  const dispatch = useDispatch();
  const { data: mnos = [] } = useGetMnosQuery();
  const platforms = filterByMnoStatus(mnos, useSelector(selectPlatforms), ['testing', 'deployed']);
  const { loading } = useSelector(({ billing }) => billing.platforms);
  const user = useSelector(({ auth }) => auth.user);
  const isSupportRole = hasUserSupportRole(user.role);

  useEffect(() => {
    dispatch(getPlatforms());
  }, [dispatch]);

  return (
    <>
      <Root
        header={
          <PageHeader
            iconName="layers"
            title="Platforms"
            buttonName="New Platform"
            noHeaderButton={!isSupportRole}
            onButtonClick={() => dispatch(openNewPlatformDialog())}
          />
        }
        content={
          loading ? (
            <FuseLoading />
          ) : (
            <CustomDataGrid
              name="platforms"
              rows={platforms}
              columns={columns}
              onRefresh={() => dispatch(getPlatforms())}
              density="compact"
              onRowClick={(newSelectionModel) => {
                if (isSupportRole) dispatch(openEditPlatformDialog(newSelectionModel.row));
              }}
            />
          )
        }
        innerScroll
      />
      <PlatformDialog />
      <BillingSpinner />
    </>
  );
}

export default withReducer('billing', reducer)(Platforms);
