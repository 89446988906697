import { JourneyResultMap, JourneyStepTypeMap } from 'app/services/constants';
import _ from '@lodash';
import { isNode, MarkerType, Position } from 'reactflow';
import dagre from 'dagre';

export function mergeSameDestinationTransitions(data) {
  const seen = {};
  return data.filter((entry) => {
    let previous;
    const { condition, task, responseText, ...rest } = entry;
    if (seen[JSON.stringify(rest)]) {
      previous = seen[JSON.stringify(rest)];
      const label = responseText ? responseText.toUpperCase() : condition;
      if (!previous.condition.includes(label))
        previous.condition = `${previous.condition} | ${label}`;
      // merge it into the previous one and discard
      return false;
    }
    entry.condition = responseText ? responseText.toUpperCase() : condition;
    seen[JSON.stringify(rest)] = entry;
    return true;
  });
}

export function getElements(steps, transitions, isSupportRole) {
  const backTransition = findCycleAndItsBackStep(transitions, steps);
  const stepMap = Array.from(Object.entries(steps)).map(([key, value]) => ({
    id: key,
    position: { x: 0, y: 0 },
    data: { ...value, id: key },
    hidden: !isSupportRole && !!value.hidden,
    type: getNodeTypeByStepType(value.stepType),
    ...(backTransition &&
      (backTransition.originatingStep === key || backTransition.destinationStep === key) && {
        backNode: true,
      }),
  }));
  const transitionMap = mergeSameDestinationTransitions(_.cloneDeep(transitions)).map(
    (transition) => {
      return {
        id: `${transition.originatingStep}-${transition.destinationStep}-${transition.condition}-${transition.responseText}`,
        source: transition.originatingStep,
        target: transition.destinationStep,
        label: shortenLabels(transition.condition, isSupportRole),
        labelStyle: { fontSize: '1rem' },
        position: { x: 0, y: 0 },
        hidden: !isSupportRole && !!transition.hidden,
        type: 'smart',
        style: getEdgeStyle(transition.condition),
        animated: true,
        markerEnd: {
          type: MarkerType.Arrow,
          color: getEdgeColor(transition.condition),
        },
        ...(backTransition &&
          backTransition.originatingStep === transition.originatingStep &&
          backTransition.destinationStep === transition.destinationStep && { backEdge: true }),
      };
    }
  );
  return [...stepMap, ...transitionMap];
}

const STEP_WIDTH = 400;
const MIN_STEP_HEIGHT = 400;
const MAX_STEP_HEIGHT = 800;
const NEW_LINE_HEIGHT = 20;

function getStepContentHeight(el) {
  const data = el.data || {};
  let height = MIN_STEP_HEIGHT;
  if (data.optionalDisplayText) {
    height += data.optionalDisplayText.split('\n').length * NEW_LINE_HEIGHT;
  }
  if (data.text) {
    height += data.text.split('\n').length * NEW_LINE_HEIGHT;
  } else if (data.message) {
    height += data.message.split('\n').length * NEW_LINE_HEIGHT;
  }
  return Math.min(MAX_STEP_HEIGHT, height);
}

export function getLayoutedElements(elements) {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  dagreGraph.setGraph({ rankdir: 'TB' });

  elements.forEach((el) => {
    if (isNode(el)) {
      dagreGraph.setNode(el.id, {
        width: STEP_WIDTH,
        height: getStepContentHeight(el),
      });
    } else {
      dagreGraph.setEdge(el.source, el.target);
    }
  });

  dagre.layout(dagreGraph);

  const result = elements.map((el) => {
    if (isNode(el)) {
      const { width, height, x, y } = dagreGraph.node(el.id);
      el.targetPosition = Position.Top;
      el.sourcePosition = Position.Bottom;
      el.position = {
        x: x - width / 2,
        y: y - height / 2,
      };
    }
    return el;
  });
  const [nodeList, edgeList] = splitList(result);
  return { nodeList, edgeList };
}

function splitList(elements) {
  return elements.reduce(
    ([nodeList, otherList], el) =>
      isNode(el) ? [[...nodeList, el], otherList] : [nodeList, [...otherList, el]],
    [[], []]
  );
}

export function getNodeTypeByStepType(stepType) {
  return JourneyStepTypeMap[stepType]
    ? JourneyStepTypeMap[stepType].name
    : JourneyStepTypeMap.DisplayTextStep.name;
}

export function shortenLabels(data, isSupportRole = false) {
  const items = data.split(' | ');
  const processedItems = items.map((item) => {
    Object.entries(JourneyResultMap).forEach(([key, value]) => {
      if (item.includes(key) && !isSupportRole) {
        item = value.name;
      } else {
        item = item.replace(key, value.name);
      }
    });
    return item;
  });
  return Array.from(new Set(processedItems)).join(' | ');
}

export function getEdgeColor(data) {
  for (const key of Object.keys(JourneyResultMap)) {
    if (data.includes(key)) {
      return JourneyResultMap[key].color;
    }
  }
  return null;
}

export function getEdgeStyle(data) {
  const edgeColor = getEdgeColor(data);
  return edgeColor
    ? { stroke: getEdgeColor(data), strokeWidth: 2 }
    : { stroke: 'blue', strokeWidth: 2 };
}

function findCycleAndItsBackStep(transitions, steps) {
  // Helper function to perform DFS and find a cycle
  function dfs(current, visited, recStack, path) {
    if (recStack.has(current)) {
      return { cyclePath: [...path, current], found: true };
    }
    if (visited.has(current)) {
      return { cyclePath: [], found: false };
    }

    visited.add(current);
    recStack.add(current);
    path.push(current);

    for (const neighbour of graph[current] || []) {
      const { cyclePath, found } = dfs(
        neighbour,
        new Set(visited),
        new Set(recStack),
        Array.from(path)
      );
      if (found) {
        return { cyclePath, found };
      }
    }

    recStack.delete(current);
    return { cyclePath: [], found: false };
  }

  // Constructing a graph from the transitions
  const graph = {};
  transitions.forEach((transition) => {
    if (!graph[transition.originatingStep]) {
      graph[transition.originatingStep] = [];
    }
    graph[transition.originatingStep].push(transition.destinationStep);
  });

  // Find the start step
  const startStep = Object.keys(steps).find((step) => steps[step].startStep);

  // Detect cycle
  for (const node of Object.keys(graph)) {
    const visited = new Set();
    const recStack = new Set();
    const { cyclePath, found } = dfs(node, visited, recStack, []);

    if (found) {
      const loopStart = cyclePath[cyclePath.length - 1];
      const backTransition = transitions.find(
        (transition) =>
          transition.destinationStep === loopStart &&
          transition.originatingStep === cyclePath[cyclePath.length - 2]
      );

      return backTransition; // Return the back transition record
    }
  }

  return null;
}

export function getStepComponents(type) {
  return Object.fromEntries(
    Object.entries(JourneyStepTypeMap).map(([key, value]) => [[key], value[type]])
  );
}
