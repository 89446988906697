import FusePageCarded from '@fuse/core/FusePageCarded';
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import CustomDataGrid from 'app/shared-components/CustomDataGrid';
import { useNavigate } from 'react-router-dom';
import FuseLoading from '../../../../@fuse/core/FuseLoading';
import withReducer from '../../../store/withReducer';
import reducer from '../store';
import PageHeader from '../../../shared-components/PageHeader';
import TemplatesAction from './TemplatesAction';
import { useGetTemplatesQuery } from '../store/templatesApi';

const Root = styled(FusePageCarded)(({ theme }) => ({
  '& .FusePageCarded-header': {
    minHeight: 72,
    height: 72,
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      minHeight: 136,
      height: 136,
    },
  },
  '& .FusePageCarded-content': {
    display: 'flex',
  },
  '& .FusePageCarded-contentCard': {
    overflow: 'hidden',
  },
}));

const columns = [
  {
    field: 'id',
    headerName: 'ID',
    flex: 1,
    hide: true,
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: 2,
  },
  {
    field: 'mno',
    headerName: 'MNO',
    flex: 2,
  },
  {
    field: 'product',
    headerName: 'Product',
    flex: 2,
  },
  {
    field: 'journey',
    headerName: 'Journey',
    valueGetter: (params) => params.row?.journey?.name,
    flex: 2,
  },
  {
    field: 'journeyId',
    headerName: 'Journey ID',
    valueGetter: (params) => params.row?.journey?.id,
    hide: true,
    flex: 2,
  },
  { field: 'createdAt', headerName: 'Created At', flex: 1 },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    flex: 1,
    hide: true,
    valueGetter: (params) =>
      params.row?.updatedAt > params.row?.journey?.updatedAt
        ? params.row?.updatedAt
        : params.row?.journey?.updatedAt,
  },
  {
    field: 'isDisabled',
    headerName: 'Disabled',
    flex: 1,
    valueGetter: (params) => (params.row?.isDisabled ? 'Yes' : 'No'),
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: 'Actions',
    flex: 1.5,
    renderCell: (cellValues) => {
      return <TemplatesAction cellValues={cellValues} />;
    },
  },
];

function Templates() {
  const dispatch = useDispatch();
  const { data: templates = [], isLoading, refetch } = useGetTemplatesQuery();
  const navigate = useNavigate();

  return (
    <>
      <Root
        header={
          <PageHeader
            iconName="list"
            title="Templates"
            buttonName="New Template"
            onButtonClick={() => navigate(`/support/template/new`)}
          />
        }
        content={
          isLoading ? (
            <FuseLoading />
          ) : (
            <CustomDataGrid
              name="Templates"
              rows={templates}
              columns={columns}
              defaultSorting={[
                {
                  field: 'isDisabled',
                  sort: 'asc',
                },
                {
                  field: 'name',
                  sort: 'asc',
                },
              ]}
              onRefresh={() => refetch()}
              onRowClick={(row) => navigate(`/support/template/${row.id}`)}
              getRowClassName={(params) => (params.row.isDisabled ? 'bg-red-100' : '')}
              defaultFilter={{
                items: [
                  {
                    columnField: 'isDisabled',
                    operatorValue: 'equals',
                    id: 1000,
                    value: 'No',
                  },
                ],
              }}
            />
          )
        }
        innerScroll
      />
    </>
  );
}

export default withReducer('admin', reducer)(Templates);
