import { addDays, addHours, addMonths } from 'date-fns';
import format from 'date-fns/format';
import moment from 'moment-timezone';
import ComponentLogger from 'app/services/logger/ComponentLogger';
import { Constants } from './constants';

const logger = new ComponentLogger('DateUtil');

export function getFirstDayOfPreviousMonth() {
  return getFirstDayOfMonth(-1);
}
export function getLastDayOfPreviousMonth() {
  return getLastDayOfMonth(-1);
}

export function getFirstDayOfTwoMonthsAgo() {
  return getFirstDayOfMonth(-2);
}
export function getLastDayOfTwoMonthsAgo() {
  return getLastDayOfMonth(-2);
}

export function getFirstDayOfThisMonth() {
  return getFirstDayOfMonth(0);
}

export function getLastDayOfThisMonth() {
  return getLastDayOfMonth(0);
}

export function getFirstDayOfNextMonth() {
  return getFirstDayOfMonth(1);
}

export function getLastDayOfNextMonth() {
  return getLastDayOfMonth(1);
}

export function getDateOf30DaysAgo() {
  const date = addDays(new Date(), -30);
  return toDatePartStr(date);
}

export function toMnoLocalTimeStr(utcDateStr, mno, formatStr = Constants.DEFAULT_DATE_TIME_FORMAT) {
  if (!utcDateStr) return null;
  try {
    const mnoDate =
      mno in Constants.MNO_UTC_OFFSETS
        ? addHours(new Date(utcDateStr), Constants.MNO_UTC_OFFSETS[mno])
        : new Date(utcDateStr);
    const localDateStr = mnoDate.toISOString().substring(0, 19); // exclude TZ info
    return format(new Date(localDateStr), formatStr);
  } catch (error) {
    logger.error('toMnoLocalTimeStr', { error });
    return utcDateStr;
  }
}

export function getDateDiff(date1) {

  const diff = new Date() - date1;

  // Calculate difference in minutes
  const diffInMinutes = diff / 1000 / 60;

  // Calculate difference in hours
  const diffInHours = diff / 1000 / 60 / 60;

  // Calculate difference in days
  const diffInDays = diff / 1000 / 60 / 60 / 24;

  // Use appropriate unit
  if (diffInMinutes < 60) {
    return Math.round(diffInMinutes) + " minutes";
  } else if (diffInHours < 24) {
    return Math.round(diffInHours) + " hours";
  } else {
    return Math.round(diffInDays) + " days";
  }
}

export function isOrderDateValidForBidding(timezone, validFrom) {
  const convertedDateTime = moment(new Date()).tz(timezone);
  const sameDay = moment(convertedDateTime).isSame(validFrom, 'day');
  return !sameDay;
}

export function getActivePrice(priceList) {
  return priceList.filter((price) => moment(new Date()).isBetween(price.validFrom, price.validTo));
}

// Helper functions
function getFirstDayOfMonth(monthOffset) {
  const date = addMonths(new Date(), monthOffset);
  date.setDate(1);
  return toDatePartStr(date);
}
function getLastDayOfMonth(monthOffset) {
  const date = addMonths(new Date(), monthOffset + 1);
  date.setDate(0);
  return toDatePartStr(date);
}
function toDatePartStr(date) {
  return date.toISOString().substring(0, 10);
}

export function getCurrentDateStr() {
  const date = new Date();
  return `${
    date.getFullYear() + `0${date.getMonth() + 1}`.slice(-2) + `0${date.getDate()}`.slice(-2)
  }`;
}

export function isValidDate(dateString) {
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  if (!regex.test(dateString)) {
    return false;
  }
  try {
    const date = new Date(dateString);
    // Check if the date is valid and the input string did not cause any parsing issues
    return !isNaN(date) && date.toISOString().slice(0, 10) === dateString;
  } catch (error) {
    return false;
  }
}

export const addTimeToDate = (date, time) => {
  const [hours, minutes] = time.split(':').map(Number);
  return moment(date).hours(hours).minutes(minutes).toDate();
};

export function formatDateAsYYYYMMDD(date) {
  const localDate = new Date(date);
  const year = localDate.getFullYear();
  const month = (`0${localDate.getMonth() + 1}`).slice(-2); // Add leading zero
  const day = (`0${localDate.getDate()}`).slice(-2); // Add leading zero
  return `${year}-${month}-${day}`;
}