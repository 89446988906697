import { styled } from '@mui/material/styles';
import FusePageCarded from '@fuse/core/FusePageCarded';
import { Grid } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { JsonEditor as Editor } from 'jsoneditor-react';
import ace from 'brace';
import Alert from '@mui/material/Alert';
import { hasUserSupportRole } from 'app/services/permissionUtil';
import { showMessage } from 'app/store/fuse/messageSlice';
import FlowComponent from 'app/shared-components/flow/FlowComponent';
import Error404Page from '../../404/Error404Page';
import withReducer from '../../../store/withReducer';
import reducer from '../store';
import JourneyHeader from './JourneyHeader';
import 'jsoneditor-react/es/editor.min.css';
import 'brace/mode/json';
import 'brace/theme/github';
import FuseLoading from '../../../../@fuse/core/FuseLoading';
import { useGetJourneysQuery, useSaveJourneyMutation } from '../store/journeysApi';
import { useGetTemplatesQuery } from '../../admin/store/templatesApi';
import { getElements } from 'app/shared-components/flow/FlowHelper';

const Root = styled(FusePageCarded)({
  '& .FusePageCarded-header': {},
  '& .FusePageCarded-toolbar': {},
  '& .FusePageCarded-content': {},
  '& .FusePageCarded-sidebarHeader': {},
  '& .FusePageCarded-sidebarContent': {},
});

function Journey(props) {
  const { journeyId } = useParams();
  const { data: journeys = [], isLoading: loading } = useGetJourneysQuery();
  const [saveJourney] = useSaveJourneyMutation();
  const journey = journeys?.find((j) => j.id === journeyId);
  const dispatch = useDispatch();
  const [jsonData, setJsonData] = useState(null);
  const [journeyDataFromEditor, setJourneyDataFromEditor] = useState(null);
  const [error, setError] = useState(null);
  const aceEditorRef = useRef(null);
  const jsonEditorRef = useRef(null);
  const navigate = useNavigate();
  const [editorReady, setEditorReady] = useState(false);
  const { data: templates = [] } = useGetTemplatesQuery();

  const { role } = useSelector(({ auth }) => auth.user);

  const flowElements =
    journeyDataFromEditor?.steps && journeyDataFromEditor?.transitions
      ? getElements(
          journeyDataFromEditor.steps,
          journeyDataFromEditor.transitions,
          hasUserSupportRole(role)
        )
      : [];

  useEffect(() => {
    if (jsonData !== null && jsonEditorRef.current !== null) {
      jsonEditorRef.current.set(jsonData);
      const editor = jsonEditorRef.current;
      aceEditorRef.current = editor.aceEditor;
      aceEditorRef.current.getSession().setMode('ace/mode/json');
      aceEditorRef.current.getSession().on('changeAnnotation', handleAnnotationChange);
      setJourneyDataFromEditor(jsonData);
      return () => {
        aceEditorRef.current.getSession().off('changeAnnotation', handleAnnotationChange);
      };
    }
  }, [jsonData, editorReady]);

  const handleEditorChange = (updatedData) => {
    try {
      setJourneyDataFromEditor(updatedData);
      setError(null);
    } catch (e) {
      setError(e.message);
    }
  };

  const setRef = (instance) => {
    if (instance) {
      jsonEditorRef.current = instance.jsonEditor;
    } else {
      jsonEditorRef.current = null;
    }
  };

  useEffect(() => {
    if (jsonEditorRef.current) {
      setEditorReady(true);
    }
  }, [jsonEditorRef]);

  useEffect(() => {
    if (journey) {
      const data = {
        ...journey,
        ...(journey.steps && { steps: JSON.parse(journey.steps) }),
        ...(journey.tags && { tags: JSON.parse(journey.tags) }),
        ...(journey.billing && { billing: JSON.parse(journey.billing) }),
        ...(journey.metrics && { metrics: JSON.parse(journey.metrics) }),
      };
      setJsonData(data);
      setJourneyDataFromEditor(data);
    } else if (journeyId === 'new') {
      setJsonData({ name: '' });
      setJourneyDataFromEditor({ name: '' });
    }
  }, [journey, journeyId, dispatch]);

  if (loading) {
    return <FuseLoading />;
  }

  if (!journey && journeyId !== 'new') {
    return <Error404Page />;
  }

  function getActiveTemplateNames() {
    const activeTemplates = templates.filter(
      (template) => template.isDisabled !== true && template.journeyID === journeyId
    );
    return activeTemplates.map((template) => template.name);
  }

  function handleSaveJourney() {
    const lastJourney = jsonEditorRef.current.get();
    const activeTemplates = getActiveTemplateNames();
    if (lastJourney.isDisabled === true && activeTemplates.length > 0) {
      dispatch(
        showMessage({
          message: `Journey cannot be disabled because it is used in the following active templates: 
          ${activeTemplates.join(', ')}`,
          variant: 'error',
        })
      );
      return;
    }

    try {
      const data = {
        ...lastJourney,
        steps: JSON.stringify(lastJourney.steps),
        tags: JSON.stringify(lastJourney.tags),
        billing: JSON.stringify(lastJourney.billing),
        metrics: JSON.stringify(lastJourney.metrics),
      };
      saveJourney(data).then((response) => {
        if (!response.error) {
          navigate('/support/journeys');
        }
      });
    } catch (e) {
      dispatch(
        showMessage({
          message: `Syntax has been failed!, error: ${JSON.stringify(e.message)}`,
          variant: 'error',
        })
      );
    }
  }

  const handleAnnotationChange = () => {
    try {
      JSON.parse(aceEditorRef.current.getSession().getValue());
      setError(null);
    } catch (e) {
      setError(e.message);
    }
  };

  return (
    <>
      <Root
        header={
          <JourneyHeader journey={jsonData} error={error} onButtonClick={handleSaveJourney} />
        }
        content={
          <Grid container direction="column" alignItems="center" spacing={3} p={3}>
            <Grid item container xs={3} spacing={3}>
              <Grid item xs={12}>
                {error && <Alert severity="error">{error}</Alert>}
              </Grid>
              <Grid item xs={12} md={6}>
                {jsonData && (
                  <div>
                    <Editor
                      mode="code"
                      ref={setRef}
                      value={jsonData}
                      ace={ace}
                      statusBar
                      theme="ace/theme/github"
                      htmlElementProps={{
                        style: {
                          height: 700,
                        },
                      }}
                      onChange={handleEditorChange}
                    />
                  </div>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <FlowComponent fields={flowElements} />
              </Grid>
            </Grid>
          </Grid>
        }
        innerScroll
      />
    </>
  );
}

export default withReducer('support', reducer)(Journey);
