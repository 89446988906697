import { styled } from '@mui/material/styles';
import FusePageCarded from '@fuse/core/FusePageCarded';
import * as yup from 'yup';
import _ from '@lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CodeHeader from './CodeHeader';
import FormInputText from '../../../shared-components/FormInputText';
import FormInputDropdown from '../../../shared-components/FormInputDropdown';
import PriceTable from './PriceTable';
import FuseLoading from '../../../../@fuse/core/FuseLoading';
import FormAccountDropdown from '../../../shared-components/FormAccountDropdown';
import Error404Page from '../../404/Error404Page';
import PriceDialog from './PriceDialog';
import withReducer from '../../../store/withReducer';
import reducer from '../store';
import { openPriceDialog } from '../store/pricesSlice';
import FormMnoDropdown from '../../../shared-components/FormMnoDropdown';
import { isUserBillingAdmin } from '../../../services/permissionUtil';
import { useGetCodesQuery, useGetProductFamiliesQuery } from '../store/codesApi';
import { useGetPricesOfCodeQuery } from '../store/pricesApi';

const Root = styled(FusePageCarded)({
  '& .FusePageCarded-header': {},
  '& .FusePageCarded-toolbar': {},
  '& .FusePageCarded-content': {},
  '& .FusePageCarded-sidebarHeader': {},
  '& .FusePageCarded-sidebarContent': {},
});

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  name: yup.string().required('You must enter a code name'),
  mno: yup.string().required('You must enter a MNO'),
  method: yup.string().required('You must choose a method'),
  defaultProductCode: yup.string().nullable(),
  defaultBillingAccountId: yup.string().nullable(),
  mnoShortCode: yup.string().nullable(),
  productFamilyId: yup.string().required('You must select Product Family'),
});

const defaultValues = {
  name: '',
  mno: '',
  method: '',
  defaultProductCode: '',
  defaultBillingAccountId: '',
  mnoShortCode: '',
  productFamilyId: '',
};

function Code(props) {
  const { codeId } = useParams();
  const { data: productFamilies = [], isLoading: isProductFamiliesLoading } = useGetProductFamiliesQuery();
  const { data: codes = [], isLoading: isCodesLoading, isFetching } = useGetCodesQuery(true);
  const code = codes.find((c) => c.id === codeId);
  const dispatch = useDispatch();
  const { data: prices = [] } = useGetPricesOfCodeQuery(codeId);
  const user = useSelector(({ auth }) => auth.user);
  const isBillingAdmin = isUserBillingAdmin(user);
  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { control, reset, watch, setValue } = methods;
  const selectedMno = watch('mno');
  const selectBillingMethod = watch('method');

  useEffect(() => {
    if (code) {
      reset({
        ...defaultValues,
        ...code,
      });
    }
  }, [code, codeId, dispatch, reset]);

  if (isCodesLoading || isProductFamiliesLoading) {
    return <FuseLoading />;
  }

  if (!code && codeId !== 'new' && !isFetching) {
    return <Error404Page />;
  }

  function handleMnoChange(e) {
    setValue('defaultBillingAccountId', '');
  }

  return (
    <>
      <FormProvider {...methods}>
        <Root
          header={<CodeHeader isBillingAdmin={isBillingAdmin} />}
          content={
            <Grid container direction="column" alignItems="center" spacing={3} p={3}>
              <Grid item container xs={3} spacing={3}>
                <Grid item xs={12} md={6}>
                  <FormInputText
                    name="name"
                    label="Name"
                    control={control}
                    required
                    autoFocus
                    disabled={!isBillingAdmin}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormMnoDropdown
                    name="mno"
                    label="MNO"
                    control={control}
                    disabled={!_.isEmpty(prices) || !isBillingAdmin}
                    required
                    allmno="true"
                    noPermissionFiltering
                    customHandler={(e) => handleMnoChange(e)}
                  />
                </Grid>
              </Grid>

              <Grid item container xs={3} spacing={3}>
                <Grid item xs={12} md={6}>
                  <FormInputDropdown
                    name="method"
                    label="Method"
                    control={control}
                    disabled={!_.isEmpty(prices) || !isBillingAdmin}
                    required
                    options={[
                      { value: 'OK Responses Received' },
                      { value: 'All Responses Received' },
                      { value: 'Requests Sent' },
                      { value: 'SMS' },
                      { value: 'AUCTION' },
                      /* { value: 'Product Specific' }, Uncomment after CXNPI-10383 is deployed to PROD */
                    ]}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormInputText
                    name="defaultProductCode"
                    label="Default Product Code"
                    control={control}
                    disabled={!isBillingAdmin}
                  />
                </Grid>
              </Grid>

              <Grid item container xs={3} spacing={3}>
                <Grid item xs={12} md={6}>
                  <FormAccountDropdown
                    name="defaultBillingAccountId"
                    label="Default Billing Account"
                    control={control}
                    mno={selectedMno}
                    disabled={!isBillingAdmin}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormInputText
                    name="mnoShortCode"
                    label="MNO Short code"
                    disabled={!_.isEmpty(prices) || !isBillingAdmin}
                    control={control}
                  />
                </Grid>
              </Grid>
              <Grid item container xs={3} spacing={3}>
                <Grid item xs={12} md={6}>
                  <FormInputDropdown
                    name="productFamilyId"
                    label="Product Family"
                    control={control}
                    disabled={!_.isEmpty(prices) || !isBillingAdmin}
                    required
                    options={
                      productFamilies && productFamilies.length
                        ? productFamilies.map(({ id, productFamilyName }) => {
                            return { value: id, label: productFamilyName };
                          })
                        : []
                    }
                  />
                </Grid>
              </Grid>

              <Grid item container direction="column" spacing={3}>
                <Grid item container xs={1}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h5">Prices</Typography>
                  </Grid>
                  {isBillingAdmin && (
                    <Grid item xs={12} md={6} container justifyContent="flex-end">
                      <Button
                        variant="contained"
                        color="secondary"
                        disabled={codeId === 'new'}
                        onClick={() => dispatch(openPriceDialog({ codeId }))}
                      >
                        New Price
                      </Button>
                    </Grid>
                  )}
                </Grid>
                <Grid item container>
                  <Grid item height={300} xs={12}>
                    <PriceTable
                      codeId={codeId}
                      isBillingMethodAuction={selectBillingMethod === 'AUCTION'}
                      onRowClick={(model) => {
                        if (isBillingAdmin) dispatch(openPriceDialog({ ...model.row }));
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          }
          innerScroll
        />
      </FormProvider>
      <PriceDialog selectedMno={code ? code.mno : selectedMno} />
    </>
  );
}

export default withReducer('billing', reducer)(Code);
