import FuseLoading from '../../../../@fuse/core/FuseLoading';
import CustomDataGrid from '../../../shared-components/CustomDataGrid';
import { useGetPricesOfCodeQuery } from '../store/pricesApi';

function PriceTable({ codeId, isBillingMethodAuction, onRowClick }) {
  const { data: prices = [], isLoading } = useGetPricesOfCodeQuery(codeId);

  if (isLoading) {
    return <FuseLoading />;
  }

  const showPercentIfAuction = (price) =>
    price && isBillingMethodAuction ? `${price * 100}%` : price;
  return (
    <CustomDataGrid
      rows={prices}
      columns={[
        { field: 'id', headerName: 'ID', width: 50 },
        { field: 'validFrom', type: 'date', headerName: 'Valid From', width: 100 },
        { field: 'validTo', type: 'date', headerName: 'Valid To', width: 100 },
        { field: 'contractName', headerName: 'Contract Name', width: 100 },
        {
          field: 'subscriberPrice',
          headerName: 'Subscriber Price',
          flex: 100,
          valueFormatter: (p) => showPercentIfAuction(p.value),
        },
        { field: 'subscriberPriceCurrency', headerName: 'Sub. Currency', width: 50 },
        {
          field: 'edrPrice',
          headerName: 'Edr Price',
          flex: 100,
          valueFormatter: (p) => showPercentIfAuction(p.value),
        },
        { field: 'edrPriceCurrency', headerName: 'Edr Price Currency', width: 50 },
      ]}
      autoPageSize
      pagination
      density="compact"
      onRowClick={onRowClick}
    />
  );
}

export default PriceTable;
