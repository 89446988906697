import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

const correctionsAdapter = createEntityAdapter({});

export const { selectAll: selectCorrections, selectById: selectCorrectionById } =
  correctionsAdapter.getSelectors((state) => state.billing.corrections);

const correctionsSlice = createSlice({
  name: 'billing/corrections',
  initialState: correctionsAdapter.getInitialState({
    correctionDialog: {
      type: 'new',
      props: {
        open: false,
      },
    },
  }),
  reducers: {
    openNewCorrectionDialog: (state, action) => {
      state.correctionDialog = {
        type: 'new',
        props: {
          open: true,
        },
      };
    },
    closeNewCorrectionDialog: (state, action) => {
      state.correctionDialog = {
        type: 'new',
        props: {
          open: false,
        },
      };
    },
  },
});

export const { openNewCorrectionDialog, closeNewCorrectionDialog } = correctionsSlice.actions;

export default correctionsSlice.reducer;
