import FusePageSimple from '@fuse/core/FusePageSimple';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { forwardRef, useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, InputAdornment, TextField, Tooltip } from '@mui/material';
import NumberFormat from 'react-number-format';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import PageHeader from '../../shared-components/PageHeader';
import { getDecimalScale } from '../orders/order/orderHelper';
import FormAccountDropdown from '../../shared-components/FormAccountDropdown';
import FormInputText from '../../shared-components/FormInputText';
import FormMnoDropdown from '../../shared-components/FormMnoDropdown';
import { getPaymentInformation } from '../orders/store/orderSlice';
import { openDialog } from '../../store/fuse/dialogSlice';
import PaymentRedirectDialog from './PaymentRedirectDialog';
import { getStyledCurrencyNumber } from '../../services/orderUtil';
import { existsOrEqualsZero } from '../../services/generalUtil';
import { useGetMnosQuery } from '../support/store/mnosApi';
import { useGetAccountsQuery } from '../billing-admin/store/accountsApi';

const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
}));

const MIN_AMOUNT = 10000;

const NumberFormatCustom = forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      decimalScale={getDecimalScale()}
    />
  );
});

function Payment(param) {
  const [fwConfig, setFwConfig] = useState();
  const dispatch = useDispatch();
  const location = useLocation();
  const [selectedAccountId, setSelectedAccountId] = useState(location?.state?.billingAccount);
  const { data: accounts } = useGetAccountsQuery();
  const selectedAccount = accounts?.find(acc => acc.id === selectedAccountId);
  const getCurrency = () => selectedAccount?.amountDueLimitCurrency || 'NGN';

  const schema = yup.object().shape({
    email: yup.string().required().email('You must enter a valid email'),
    amount: yup
      .number()
      .typeError('Amount must be a number')
      .required('You must enter an amount')
      .transform((_, val) => (val !== '' ? Number(val) : null))
      .min(
        MIN_AMOUNT,
        `The minimum amount to pay is ${getStyledCurrencyNumber(MIN_AMOUNT, getCurrency())}`
      ),
    phone: yup.string().nullable(),
    mno: yup.string().required('You must select an mno'),
    billingAccount: yup
      .string()
      .required('You must select billing account that you want to pop up'),
  });

  const user = useSelector(({ auth }) => auth.user);
  const { data: mnos = [] } = useGetMnosQuery();
  // TODO
  const { accountPaymentInformation, accountPaymentInformationLoading } = useSelector(
    ({ journey }) => journey.order
  );

  const defaultValues = {
    mno: location?.state?.mno || (getMNOList().length === 1 ? getMNOList()[0] : ''),
    email: user?.data?.email,
    phone: user?.data?.phoneNumber,
    billingAccount: location?.state?.billingAccount || '',
    amount: location?.state?.amount || '',
  };
  window.history.replaceState({}, document.title);

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { control, formState, getValues, watch } = methods;
  const selectedMno = watch('mno');
  const currentAmount = watch('amount');
  const { isValid, dirtyFields } = formState;

  useEffect(() => {
    if (!selectedAccountId || !selectedAccount) return;
    dispatch(
      getPaymentInformation({
        mno: selectedMno,
        accountId: selectedAccount.id,
        currency: selectedAccount.amountDueLimitCurrency,
      })
    );
  }, [dispatch, selectedAccountId]);

  const handleBillingAccountChange = (accountId) => setSelectedAccountId(accountId);
  const handleMnoChange = () => setSelectedAccountId(null);

  function getMNOList() {
    return user?.permissions
      .map((permission) => permission.mno)
      .flat()
      .filter((mno) => process.env.REACT_APP_PAYMENT_ENABLED_MNO_LIST.includes(mno));
  }

  const handlePayClick = () => {
    const formData = getValues();

    const requestData = {
      amount: parseFloat(formData.amount).toFixed(1),
      email: formData.email,
      phone: formData.phone,
      mnoId: mnos.find((mno) => mno.mno === formData.mno).id,
      billingAccountId: formData.billingAccount,
    };

    dispatch(
      openDialog({
        children: <PaymentRedirectDialog requestData={requestData} />,
      })
    );
  };

  function filterBillingAccountWithPayment(account) {
    return existsOrEqualsZero(account.amountDueLimit) && account.amountDueLimitCurrency;
  }

  return (
    <FormProvider {...methods}>
      <Root
        header={<PageHeader iconName="settings" title="Payment" noHeaderButton />}
        content={
          <div className="flex flex-auto justify-center w-full max-w-512 mx-auto p-24 sm:p-32">
            <div className="md:flex">
              <div className="w-full flex flex-col flex-1 md:ltr:pr-32 md:rtl:pl-32">
                <Card className="w-full mb-32">
                  <div className="px-14 pt-24">
                    <Typography className="text-2xl font-semibold leading-tight text-center	">
                      Make a Payment
                    </Typography>
                  </div>
                  <div className="px-32 pt-24">
                    <Grid container direction="column" alignItems="center" spacing={3} p={3}>
                      <Grid item container xs={12} md={4} spacing={3}>
                        <Grid item xs={12}>
                          <FormInputText
                            hidden
                            name="email"
                            label="Email"
                            control={control}
                            required
                            disabled
                          />
                        </Grid>
                        {user?.data?.phoneNumber && (
                          <Grid item xs={12}>
                            <FormInputText
                              hidden
                              name="phone"
                              label="Phone"
                              control={control}
                              required
                              disabled
                            />
                          </Grid>
                        )}

                        <Grid
                          hidden={location?.state?.mno || getMNOList().length === 1}
                          item
                          xs={12}
                        >
                          <FormMnoDropdown
                            name="mno"
                            label="MNO"
                            control={control}
                            required
                            data-testid="mno-dropdown"
                            customHandler={handleMnoChange}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <FormAccountDropdown
                            name="billingAccount"
                            label="Billing Account"
                            control={control}
                            mno={location?.state?.mno || selectedMno}
                            additionaFilter={filterBillingAccountWithPayment}
                            required
                            data-testid="billingAccount-dropdown"
                            customHandler={(e) => handleBillingAccountChange(e.target.value)}
                          />
                        </Grid>
                        {selectedAccountId && (
                          <Grid item xs={12}>
                            <div className="text-sm mx-10">
                              <Tooltip
                                style={{ marginTop: '-2px' }}
                                title="This is the amount you can use for new orders"
                              >
                                <InfoIcon color="secondary" className="text-md" fontSize="large" />
                              </Tooltip>
                              &nbsp;
                              <span className="font-semibold">Amount Available:&nbsp;</span>
                              <span>
                                {accountPaymentInformationLoading || !accountPaymentInformation
                                  ? 'Calculating - please wait...'
                                  : getStyledCurrencyNumber(
                                      accountPaymentInformation.amountAvailable,
                                      getCurrency()
                                    )}
                              </span>
                            </div>
                          </Grid>
                        )}

                        <Grid item xs={12}>
                          <Controller
                            name="amount"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <TextField
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {getCurrency()}
                                    </InputAdornment>
                                  ),
                                }}
                                {...field}
                                error={!!error}
                                helperText={
                                  error?.message ||
                                  (defaultValues.amount &&
                                    currentAmount?.toString() === defaultValues.amount.toString() &&
                                    `To be able to submit your last order, make a payment of ${getStyledCurrencyNumber(
                                      defaultValues.amount,
                                      getCurrency()
                                    )} or more`)
                                }
                                label="Amount"
                                fullWidth
                                data-testid="amount-dropdown"
                                required
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>

                  <CardContent className="px-32 py-24">
                    <p className="block text-sm px-24">
                      This payment amount includes VAT
                      <br />
                      <br />
                      Please note, the MTN Payment Gateway will charge a processing fee of 1% (to a
                      maximum of N1,500) plus VAT. The final payment amount will be displayed at
                      check-out.
                    </p>
                    <div className="mt-24 text-center">
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={!isValid}
                        onClick={handlePayClick}
                      >
                        Pay with MTN
                      </Button>
                    </div>
                  </CardContent>
                </Card>
              </div>
            </div>
          </div>
        }
      />
    </FormProvider>
  );
}

export default Payment;
