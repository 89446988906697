import { yupResolver } from '@hookform/resolvers/yup';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import _ from 'lodash';
import * as yup from 'yup';

import { Grid } from '@mui/material';
import { existsOrEqualsZero } from 'app/services/generalUtil';
import { closeNewAccountDialog, closeEditAccountDialog } from '../store/accountsSlice';
import FormMnoDropdown from '../../../shared-components/FormMnoDropdown';
import FormInputDropdown from '../../../shared-components/FormInputDropdown';
import { useSaveAccountMutation } from '../store/accountsApi';

const defaultValues = {
  name: '',
  mno: '',
  email: '',
  amountDueLimit: '',
  amountDueLimitCurrency: '',
};

/**
 * Form Validation Schema
 */
const schema = yup.object().shape(
  {
    name: yup.string().required('You must enter a name'),
    mno: yup.string().required('You must enter an mno'),
    email: yup.string().email('You must enter a valid email'),
    amountDueLimit: yup.mixed().when('amountDueLimitCurrency', {
      is: (amountDueLimitCurrency) => !!amountDueLimitCurrency,
      then: yup
        .number()
        .typeError('Amount due limit should be a valid number!')
        .required('Limit is required when currency is set'),
      otherwise: yup
        .mixed()
        .test('is-numeric-or-null', 'Amount due limit must be numeric', (value) => {
          return value === '' || !Number.isNaN(parseFloat(value));
        }),
    }),
    amountDueLimitCurrency: yup.string().when('amountDueLimit', {
      is: (amountDueLimit) => existsOrEqualsZero(amountDueLimit),
      then: yup
        .string()
        .typeError('Amount Due Limit Currency must be a valid string')
        .required('Currency is required when limit is set.'),
      otherwise: yup.string().nullable().notRequired(),
    }),
  },
  [
    ['amountDueLimit', 'amountDueLimitCurrency'],
    ['amountDueLimitCurrency', 'amountDueLimit'],
  ]
);

function AccountDialog(props) {
  const [saveAccount, { isLoading }] = useSaveAccountMutation();
  const dispatch = useDispatch();
  const contactDialog = useSelector(({ billing }) => billing.accounts.accountDialog);

  const { control, reset, handleSubmit, formState, trigger, watch, setValue } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const amountDueLimit = watch('amountDueLimit');
  const { isValid, dirtyFields, errors } = formState;
  const currencyList = process.env.REACT_APP_CURRENCY_LIST.split(',');

  /**
   * Initialize Dialog with Data
   */
  const initDialog = useCallback(() => {
    /**
     * Dialog type: 'edit'
     */
    if (contactDialog.type === 'edit' && contactDialog.data) {
      reset({ ...defaultValues, ...contactDialog.data });
    }

    /**
     * Dialog type: 'new'
     */
    if (contactDialog.type === 'new') {
      reset({
        ...defaultValues,
        ...contactDialog.data,
      });
    }
  }, [contactDialog.data, contactDialog.type, reset]);

  /**
   * On Dialog Open
   */
  useEffect(() => {
    if (contactDialog.props.open) {
      initDialog();
    }
  }, [contactDialog.props.open, initDialog]);

  /**
   * Close Dialog
   */
  function closeComposeDialog() {
    return contactDialog.type === 'edit'
      ? dispatch(closeEditAccountDialog())
      : dispatch(closeNewAccountDialog());
  }

  /**
   * Form Submit
   */
  function onSubmit(data) {
    data.name = data.name?.trim();
    if (contactDialog.type === 'new') {
      saveAccount(data);
    } else {
      saveAccount({ ...contactDialog.data, ...data });
    }
    closeComposeDialog();
  }

  return (
    <Dialog
      classes={{
        paper: 'm-24',
      }}
      {...contactDialog.props}
      onClose={closeComposeDialog}
      fullWidth
      maxWidth="xs"
    >
      <AppBar position="static" elevation={0}>
        <Toolbar className="flex w-full">
          <Typography variant="subtitle1" color="inherit">
            {contactDialog.type === 'new' ? 'New Account' : 'Edit Account'}
          </Typography>
        </Toolbar>
      </AppBar>
      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col md:overflow-hidden"
      >
        <DialogContent classes={{ root: 'p-24' }}>
          <Grid container direction="column" rowSpacing={2}>
            <Grid item>
              <Controller
                control={control}
                name="name"
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Name"
                    id="name"
                    error={!!errors.name}
                    helperText={errors?.name?.message}
                    variant="outlined"
                    required
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item>
              <FormMnoDropdown
                name="mno"
                label="MNO"
                control={control}
                required
                allmno="true"
                noPermissionFiltering
              />
            </Grid>
            <Grid item>
              <Controller
                control={control}
                name="email"
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Email"
                    id="email"
                    error={!!errors.email}
                    helperText={errors?.email?.message}
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                control={control}
                name="amountDueLimit"
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Amount Due Limit"
                    onChange={(e) => {
                      field.onChange(
                        Number.isNaN(parseFloat(e.target.value))
                          ? e.target.value
                          : parseFloat(e.target.value)
                      );
                      if (e.target.value === '') {
                        setValue('amountDueLimitCurrency', '');
                      }
                      trigger();
                    }}
                    id="amountDueLimit"
                    error={!!errors.amountDueLimit}
                    helperText={errors?.amountDueLimit?.message}
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item>
              <FormInputDropdown
                label="Amount Due Limit Currency"
                name="amountDueLimitCurrency"
                customHandler={() => {
                  trigger();
                }}
                error={!!errors.amountDueLimitCurrency}
                required={existsOrEqualsZero(amountDueLimit)}
                disabled={amountDueLimit === ''}
                control={control}
                options={currencyList.map((val) => ({ value: val }))}
              />
            </Grid>
            {existsOrEqualsZero(amountDueLimit) && (
              <Grid item>
                <p className="text-xs font-semibold text-red-600 mt-8 px-4">
                  Amount Due Limit has been set. Thus, this Billing Account will have access to
                  Payment functionality and payments will be required when Account Balance is
                  greater than Amount Due Limit. Are you sure you want to continue?
                </p>
              </Grid>
            )}
          </Grid>
        </DialogContent>

        {contactDialog.type === 'new' ? (
          <DialogActions className="justify-between p-4 pb-16">
            <div className="px-16">
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                disabled={_.isEmpty(dirtyFields) || !isValid}
              >
                Add
              </Button>
            </div>
          </DialogActions>
        ) : (
          <DialogActions className="justify-between p-4 pb-16">
            <div className="px-16">
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                disabled={_.isEmpty(dirtyFields) || !isValid}
              >
                Save
              </Button>
            </div>
          </DialogActions>
        )}
      </form>
    </Dialog>
  );
}

export default AccountDialog;
