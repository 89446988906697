import { createApi } from '@reduxjs/toolkit/query/react';
import { showMessage } from 'app/store/fuse/messageSlice';
import ComponentLogger from 'app/services/logger/ComponentLogger';
import { graphqlBaseQuery } from '../../../../graphql/base-query';
import * as queries from '../../../../graphql/queries';
import * as mutations from '../../../../graphql/mutations';
import { MAX_TEMPLATE_LIMIT } from '../permission/constants';

const logger = new ComponentLogger('TemplatesApi');

function getFilter(permissions) {
  const mnoList = permissions.map((permission) => permission.mno).flat();

  if (mnoList.includes('*')) {
    return {};
  }

  if (mnoList.length > 1) {
    const or = mnoList.map((mno) => ({ mno: { eq: mno } }));
    return { or };
  }

  return { mno: { eq: mnoList[0] } };
}

export const templatesApi = createApi({
  reducerPath: 'templatesApi',
  baseQuery: graphqlBaseQuery,
  tagTypes: ['Templates'],
  endpoints: (builder) => ({
    getTemplates: builder.query({
      async queryFn(noPermissionFiltering = false, { getState }, _extraOptions, fetchWithBQ) {
        try {
          const filter = noPermissionFiltering ? null : getFilter(getState().auth.user.permissions);

          const response = await fetchWithBQ({
            query: queries.listJourneyTemplates,
            variables: { filter, limit: MAX_TEMPLATE_LIMIT },
          });

          if (response.error) throw response.error;

          return { data: response.data.listJourneyTemplates.items || [] };
        } catch (error) {
          logger.error('listTemplates', { error });
          return { error: 'Failed to fetch templates.' };
        }
      },
      providesTags: ['Templates'],
    }),
    saveTemplate: builder.mutation({
      async queryFn(template, { dispatch }) {
        try {
          const isUpdate = !!template.createdAt;
          const mutation = isUpdate
            ? mutations.updateJourneyTemplate
            : mutations.createJourneyTemplate;

          const input = isUpdate
            ? (() => {
                const {
                  createdAt,
                  updatedAt,
                  version,
                  _version,
                  _deleted,
                  _lastChangedAt,
                  journey,
                  ...rest
                } = template;
                return rest;
              })()
            : template;

          const response = await graphqlBaseQuery({
            query: mutation,
            variables: { input },
          });

          if (response.error) {
            logger.error(isUpdate ? 'updateTemplate' : 'createTemplate', {
              data: template,
              error: response.error,
            });
            return { error: response.error };
          }

          dispatch(
            showMessage({
              message: isUpdate
                ? 'Template has been updated successfully!'
                : 'Template has been created successfully!',
              variant: 'success',
            })
          );

          return {
            data: response.data[isUpdate ? 'updateJourneyTemplate' : 'createJourneyTemplate'],
          };
        } catch (error) {
          logger.error('saveTemplate', { template, error });
          return { error: 'Failed to save the template.' };
        }
      },
      invalidatesTags: ['Templates'],
    }),
    getJourneyTemplate: builder.query({
      query: (templateId) => ({
        query: queries.getJourneyTemplate,
        variables: { id: templateId },
      }),
      transformResponse: (response) => response.getJourneyTemplate,
    }),
  }),
});

export const { useGetTemplatesQuery, useSaveTemplateMutation, useGetJourneyTemplateQuery } =
  templatesApi;
