import { useSelector, useDispatch } from 'react-redux';
import Root from 'app/shared-components/Root';
import CustomDataGrid from 'app/shared-components/CustomDataGrid';
import FuseLoading from '../../../../@fuse/core/FuseLoading';
import withReducer from '../../../store/withReducer';
import reducer from '../store';
import PageHeader from '../../../shared-components/PageHeader';
import { openMnoDialog } from '../store/mnosSlice';
import MnoDialog from './MnoDialog';
import { useGetMnosInvalidatedQuery, useSaveMnoMutation } from '../store/mnosApi';
import BillingSpinner from '../../billing-admin/BillingSpinner';

const columns = [
  { field: 'id', headerName: 'ID', flex: 1 },
  { field: 'mno', headerName: 'Mno', flex: 1 },
  { field: 'displayName', headerName: 'Display Name', flex: 1 },
  { field: 'company', headerName: 'Company', flex: 1 },
  { field: 'country', headerName: 'Country', flex: 1 },
  { field: 'countryIsoCode', headerName: 'Country Iso Code', flex: 1 },
  { field: 'localCurrency', headerName: 'Local Currency', flex: 1 },
  { field: 'timeZoneOffset', headerName: 'Time Zone Offset', flex: 1 },
  { field: 'status', headerName: 'Status', flex: 1 },
];

function Mnos({ isSystem, ...props }) {
  const dispatch = useDispatch();
  const { loading: supportLoading } = useSelector(({ support }) => support.mnos);
  const { data: mnos = [], isLoading: mnoLoading, refetch, isMnoFetching } = useGetMnosInvalidatedQuery();
  const [{ isLoading }] = useSaveMnoMutation({ fixedCacheKey: 'savedMno' });

  function onRowClick({ row }) {
    dispatch(openMnoDialog({ actionType: 'edit', actionData: row }));
  }

  const handleRefresh = () => {
    refetch();
  };

  return (
    <>
      <Root
        header={
          <PageHeader
            iconName="group"
            title="MNO"
            buttonName="New Attribute"
            onButtonClick={() => dispatch(openMnoDialog({ actionType: 'new' }))}
          />
        }
        content={
          supportLoading || mnoLoading || isMnoFetching ? (
            <FuseLoading />
          ) : (
            <CustomDataGrid
              rows={mnos}
              onRowClick={onRowClick}
              columns={columns}
              autoPageSize
              pagination
              onRefresh={handleRefresh}
              disableColumnResize={false}
            />
          )
        }
        innerScroll
      />
      <MnoDialog />
      <BillingSpinner />
    </>
  );
}

export default withReducer('support', reducer)(Mnos);
