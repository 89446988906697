import { Divider, Grid, Tooltip, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { JourneyStepTypeMap } from 'app/services/constants';
import { useReactFlow } from 'reactflow';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { showMessage } from 'app/store/fuse/messageSlice';
import { getNodeTitle } from '../../main/orders/order/orderHelper';

function FlowNodeHeader(props) {
  let title = getNodeTitle(props.stepType);
  if (!title) title = props.id;

  const { getNode } = useReactFlow();
  const dispatch = useDispatch();

  const copyContentToClipboard = useCallback(
    (event) => {
      event.stopPropagation();
      const node = getNode(props.id);

      const writeToClipboard = async (
        text,
        message = 'Content copied to clipboard!',
        variant = 'info'
      ) => {
        try {
          await navigator.clipboard.writeText(text);
          dispatch(showMessage({ message, variant }));
        } catch (error) {
          dispatch(
            showMessage({ message: 'Failed to copy content to clipboard!', variant: 'error' })
          );
        }
      };

      const getDisplayText = (content) => {
        return node.data.optionalDisplayText
          ? `${node.data.optionalDisplayText}|${content}`
          : content;
      };

      if (node?.data) {
        const { text, message, url, menuTitle, menuItemList } = node.data;
        let content = text || message || url;

        if (menuTitle) {
          content = `${menuTitle} ${menuItemList}`;
        }

        if (content) {
          writeToClipboard(getDisplayText(content));
        } else {
          dispatch(showMessage({ message: 'This content cannot be copied!', variant: 'warning' }));
        }
      } else {
        dispatch(showMessage({ message: 'No valid data to copy!', variant: 'warning' }));
      }
    },
    [props.id, getNode, dispatch]
  );

  return (
    <Grid item xs={12}>
      <div
        style={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexWrap: 'wrap',
        }}
      >
        <Typography
          style={{
            display: 'flex',
            alignItems: 'center',
            fontSize: '1.5rem',
            fontWeight: 'bold',
          }}
        >
          {title}
        </Typography>
        {props.stepType && JourneyStepTypeMap[props.stepType]?.isContentCanBeCopied && (
          <Tooltip title="Copy text">
            <IconButton
              style={{ position: 'absolute', right: '-10px', top: '-8px' }}
              size="small"
              onClick={copyContentToClipboard}
            >
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <Divider color="primary" />
    </Grid>
  );
}
export default FlowNodeHeader;
