import FusePageCarded from '@fuse/core/FusePageCarded';
import { styled } from '@mui/material/styles';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CustomDataGrid from 'app/shared-components/CustomDataGrid';
import { useNavigate } from 'react-router-dom';
import FuseLoading from '../../../../@fuse/core/FuseLoading';
import withReducer from '../../../store/withReducer';
import reducer from '../store';
import PageHeader from '../../../shared-components/PageHeader';
import { useGetJourneysQuery } from '../store/journeysApi';

const Root = styled(FusePageCarded)(({ theme }) => ({
  '& .FusePageCarded-header': {
    minHeight: 72,
    height: 72,
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      minHeight: 136,
      height: 136,
    },
  },
  '& .FusePageCarded-content': {
    display: 'flex',
  },
  '& .FusePageCarded-contentCard': {
    overflow: 'hidden',
  },
}));

const columns = [
  {
    field: 'id',
    headerName: 'ID',
    flex: 2,
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: 2,
  },
  { field: 'createdAt', headerName: 'Created At', flex: 1 },
  { field: 'updatedAt', headerName: 'Updated At', flex: 1 },
  {
    field: 'isDisabled',
    headerName: 'Disabled',
    flex: 1,
    valueGetter: (params) => (params.row?.isDisabled ? 'Yes' : 'No'),
  },
];

function Journeys() {
  const { data: rows = [], refetch, isLoading, isFetching } = useGetJourneysQuery();
  const navigate = useNavigate();

  return (
    <>
      <Root
        header={
          <PageHeader
            iconName="list"
            title="Journeys"
            buttonName="New Journey"
            onButtonClick={() => navigate(`/support/journey/new`)}
          />
        }
        content={
          isLoading || isFetching ? (
            <FuseLoading />
          ) : (
            <CustomDataGrid
              name="Journeys"
              rows={rows}
              columns={columns}
              onRowClick={(row) => navigate(`/support/journey/${row.id}`)}
              defaultSorting={[
                {
                  field: 'isDisabled',
                  sort: 'asc',
                },
                {
                  field: 'name',
                  sort: 'asc',
                },
              ]}
              getRowClassName={(params) => (params.row.isDisabled ? 'bg-red-100' : '')}
              defaultFilter={{
                items: [
                  {
                    columnField: 'isDisabled',
                    operatorValue: 'equals',
                    id: 1000,
                    value: 'No',
                  },
                ],
              }}
              onRefresh={() => refetch()}
            />
          )
        }
        innerScroll
      />
    </>
  );
}

export default withReducer('support', reducer)(Journeys);
