/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createJourney = /* GraphQL */ `
  mutation CreateJourney(
    $input: CreateJourneyInput!
    $condition: ModelJourneyConditionInput
  ) {
    createJourney(input: $input, condition: $condition) {
      id
      name
      steps
      transitions {
        originatingStep
        destinationStep
        condition
        responseText
        task
        hidden
      }
      tags
      billing
      metrics
      edr {
        providerId
        contentProvider
      }
      isDisabled
      reporting {
        introduction_text
        confirmation_text
        fulfillment_details
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateJourney = /* GraphQL */ `
  mutation UpdateJourney(
    $input: UpdateJourneyInput!
    $condition: ModelJourneyConditionInput
  ) {
    updateJourney(input: $input, condition: $condition) {
      id
      name
      steps
      transitions {
        originatingStep
        destinationStep
        condition
        responseText
        task
        hidden
      }
      tags
      billing
      metrics
      edr {
        providerId
        contentProvider
      }
      isDisabled
      reporting {
        introduction_text
        confirmation_text
        fulfillment_details
      }
      createdAt
      updatedAt
    }
  }
`;
export const createJourneyTemplate = /* GraphQL */ `
  mutation CreateJourneyTemplate(
    $input: CreateJourneyTemplateInput!
    $condition: ModelJourneyTemplateConditionInput
  ) {
    createJourneyTemplate(input: $input, condition: $condition) {
      id
      name
      mno
      product
      journeyID
      journey {
        id
        name
        steps
        transitions {
          originatingStep
          destinationStep
          condition
          responseText
          task
          hidden
        }
        tags
        billing
        metrics
        edr {
          providerId
          contentProvider
        }
        isDisabled
        reporting {
          introduction_text
          confirmation_text
          fulfillment_details
        }
        createdAt
        updatedAt
      }
      isDisabled
      variables {
        path
        value
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateJourneyTemplate = /* GraphQL */ `
  mutation UpdateJourneyTemplate(
    $input: UpdateJourneyTemplateInput!
    $condition: ModelJourneyTemplateConditionInput
  ) {
    updateJourneyTemplate(input: $input, condition: $condition) {
      id
      name
      mno
      product
      journeyID
      journey {
        id
        name
        steps
        transitions {
          originatingStep
          destinationStep
          condition
          responseText
          task
          hidden
        }
        tags
        billing
        metrics
        edr {
          providerId
          contentProvider
        }
        isDisabled
        reporting {
          introduction_text
          confirmation_text
          fulfillment_details
        }
        createdAt
        updatedAt
      }
      isDisabled
      variables {
        path
        value
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPermission = /* GraphQL */ `
  mutation CreatePermission(
    $input: CreatePermissionInput!
    $condition: ModelPermissionConditionInput
  ) {
    createPermission(input: $input, condition: $condition) {
      username
      permission
      productFamily
      mno
      billingAccount
      product
      campaignTemplate
      billingCode
      updaterId
      createdAt
      updatedAt
    }
  }
`;
export const updatePermission = /* GraphQL */ `
  mutation UpdatePermission(
    $input: UpdatePermissionInput!
    $condition: ModelPermissionConditionInput
  ) {
    updatePermission(input: $input, condition: $condition) {
      username
      permission
      productFamily
      mno
      billingAccount
      product
      campaignTemplate
      billingCode
      updaterId
      createdAt
      updatedAt
    }
  }
`;
export const deletePermission = /* GraphQL */ `
  mutation DeletePermission(
    $input: DeletePermissionInput!
    $condition: ModelPermissionConditionInput
  ) {
    deletePermission(input: $input, condition: $condition) {
      username
      permission
      productFamily
      mno
      billingAccount
      product
      campaignTemplate
      billingCode
      updaterId
      createdAt
      updatedAt
    }
  }
`;
export const createUserSetting = /* GraphQL */ `
  mutation CreateUserSetting(
    $input: CreateUserSettingInput!
    $condition: ModelUserSettingConditionInput
  ) {
    createUserSetting(input: $input, condition: $condition) {
      username
      msisdnSourceSetting {
        queryEnabled
        fileUploadEnabled
      }
      executionFlowStatsEnabled
      downloadAllMSISDNListsEnabled
      downloadSuccessMSISDNListsEnabled
      searchMSISDNActivity
      orderExecutionWithTime
      viewMNOBillingReport
      viewResellerBillingReport
      viewProvisionedSubscribersReport
      deviceTypeTargeting
      paymentFunctionalityEnabled
      updaterId
      createdAt
      updatedAt
    }
  }
`;
export const updateUserSetting = /* GraphQL */ `
  mutation UpdateUserSetting(
    $input: UpdateUserSettingInput!
    $condition: ModelUserSettingConditionInput
  ) {
    updateUserSetting(input: $input, condition: $condition) {
      username
      msisdnSourceSetting {
        queryEnabled
        fileUploadEnabled
      }
      executionFlowStatsEnabled
      downloadAllMSISDNListsEnabled
      downloadSuccessMSISDNListsEnabled
      searchMSISDNActivity
      orderExecutionWithTime
      viewMNOBillingReport
      viewResellerBillingReport
      viewProvisionedSubscribersReport
      deviceTypeTargeting
      paymentFunctionalityEnabled
      updaterId
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserSetting = /* GraphQL */ `
  mutation DeleteUserSetting(
    $input: DeleteUserSettingInput!
    $condition: ModelUserSettingConditionInput
  ) {
    deleteUserSetting(input: $input, condition: $condition) {
      username
      msisdnSourceSetting {
        queryEnabled
        fileUploadEnabled
      }
      executionFlowStatsEnabled
      downloadAllMSISDNListsEnabled
      downloadSuccessMSISDNListsEnabled
      searchMSISDNActivity
      orderExecutionWithTime
      viewMNOBillingReport
      viewResellerBillingReport
      viewProvisionedSubscribersReport
      deviceTypeTargeting
      paymentFunctionalityEnabled
      updaterId
      createdAt
      updatedAt
    }
  }
`;
export const createAttribute = /* GraphQL */ `
  mutation CreateAttribute(
    $input: CreateAttributeInput!
    $condition: ModelAttributeConditionInput
  ) {
    createAttribute(input: $input, condition: $condition) {
      id
      name
      value
      type
      user
      updaterId
      createdAt
      updatedAt
    }
  }
`;
export const updateAttribute = /* GraphQL */ `
  mutation UpdateAttribute(
    $input: UpdateAttributeInput!
    $condition: ModelAttributeConditionInput
  ) {
    updateAttribute(input: $input, condition: $condition) {
      id
      name
      value
      type
      user
      updaterId
      createdAt
      updatedAt
    }
  }
`;
export const deleteAttribute = /* GraphQL */ `
  mutation DeleteAttribute(
    $input: DeleteAttributeInput!
    $condition: ModelAttributeConditionInput
  ) {
    deleteAttribute(input: $input, condition: $condition) {
      id
      name
      value
      type
      user
      updaterId
      createdAt
      updatedAt
    }
  }
`;
export const createSingleMsisdnOrderExecution = /* GraphQL */ `
  mutation CreateSingleMsisdnOrderExecution(
    $input: CreateSingleMsisdnOrderExecutionInput!
    $condition: ModelSingleMsisdnOrderExecutionConditionInput
  ) {
    createSingleMsisdnOrderExecution(input: $input, condition: $condition) {
      journeyOrderId
      requestDate
      mno
      msisdn
      status
      executionId
      description
    }
  }
`;
export const createUserNotification = /* GraphQL */ `
  mutation CreateUserNotification(
    $input: CreateUserNotificationInput!
    $condition: ModelUserNotificationConditionInput
  ) {
    createUserNotification(input: $input, condition: $condition) {
      username
      orderApprovalIsRequiredByMe
      orderApprovedByMeCanceledOrTerminated
      myOrderIsApprovedOrRejected
      myOrderIsCancelledOrTerminated
      myOrderIsCompleted
      createdAt
      updatedAt
    }
  }
`;
export const updateUserNotification = /* GraphQL */ `
  mutation UpdateUserNotification(
    $input: UpdateUserNotificationInput!
    $condition: ModelUserNotificationConditionInput
  ) {
    updateUserNotification(input: $input, condition: $condition) {
      username
      orderApprovalIsRequiredByMe
      orderApprovedByMeCanceledOrTerminated
      myOrderIsApprovedOrRejected
      myOrderIsCancelledOrTerminated
      myOrderIsCompleted
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserNotification = /* GraphQL */ `
  mutation DeleteUserNotification(
    $input: DeleteUserNotificationInput!
    $condition: ModelUserNotificationConditionInput
  ) {
    deleteUserNotification(input: $input, condition: $condition) {
      username
      orderApprovalIsRequiredByMe
      orderApprovedByMeCanceledOrTerminated
      myOrderIsApprovedOrRejected
      myOrderIsCancelledOrTerminated
      myOrderIsCompleted
      createdAt
      updatedAt
    }
  }
`;
export const updateReceivedUserNotification = /* GraphQL */ `
  mutation UpdateReceivedUserNotification(
    $input: UpdateReceivedUserNotificationInput!
    $condition: ModelReceivedUserNotificationConditionInput
  ) {
    updateReceivedUserNotification(input: $input, condition: $condition) {
      username
      notificationDate
      readDate
      orderId
      orderName
      description
      status
      eventOwner
      createdAt
      updatedAt
    }
  }
`;
export const createMTNSAProviderID = /* GraphQL */ `
  mutation CreateMTNSAProviderID(
    $input: CreateMTNSAProviderIDInput!
    $condition: ModelMTNSAProviderIDConditionInput
  ) {
    createMTNSAProviderID(input: $input, condition: $condition) {
      billingAccountId
      providerIds
      createdAt
      updatedAt
    }
  }
`;
export const updateMTNSAProviderID = /* GraphQL */ `
  mutation UpdateMTNSAProviderID(
    $input: UpdateMTNSAProviderIDInput!
    $condition: ModelMTNSAProviderIDConditionInput
  ) {
    updateMTNSAProviderID(input: $input, condition: $condition) {
      billingAccountId
      providerIds
      createdAt
      updatedAt
    }
  }
`;
export const deleteMTNSAProviderID = /* GraphQL */ `
  mutation DeleteMTNSAProviderID(
    $input: DeleteMTNSAProviderIDInput!
    $condition: ModelMTNSAProviderIDConditionInput
  ) {
    deleteMTNSAProviderID(input: $input, condition: $condition) {
      billingAccountId
      providerIds
      createdAt
      updatedAt
    }
  }
`;
export const createPolicyLog = /* GraphQL */ `
  mutation CreatePolicyLog(
    $input: CreatePolicyLogInput!
    $condition: ModelPolicyLogConditionInput
  ) {
    createPolicyLog(input: $input, condition: $condition) {
      username
      approvedVersion
      createdAt
      updatedAt
    }
  }
`;
export const updatePolicyLog = /* GraphQL */ `
  mutation UpdatePolicyLog(
    $input: UpdatePolicyLogInput!
    $condition: ModelPolicyLogConditionInput
  ) {
    updatePolicyLog(input: $input, condition: $condition) {
      username
      approvedVersion
      createdAt
      updatedAt
    }
  }
`;
export const createJourneyOrder = /* GraphQL */ `
  mutation CreateJourneyOrder(
    $input: CreateJourneyOrderInput!
    $condition: ModelJourneyOrderConditionInput
  ) {
    createJourneyOrder(input: $input, condition: $condition) {
      id
      definition {
        steps
        transitions {
          originatingStep
          destinationStep
          condition
          responseText
          task
        }
        billing
        metrics
        tags
        edr {
          providerId
          contentProvider
        }
        isDisabled
        reporting {
          introduction_text
          confirmation_text
          fulfillment_details
        }
      }
      executionStatuses {
        items {
          id
          journeyOrderId
          journeyOrder {
            id
            definition {
              steps
              billing
              metrics
              tags
              isDisabled
            }
            executionStatuses {
              nextToken
            }
            name
            billingCodeId
            billingPremiumCodeId
            mno
            maxPrice
            bidPrice
            maxBudget
            targetRequested
            targetExcluded
            authorizedAmount
            handsetsToTarget
            targetable
            targeted
            exclusionListUri
            status
            validFrom
            validTo
            startTime
            endTime
            executionTimeRestrictionEnabled
            excludedDays
            targetMsisdnListUris
            templateId
            taxonomy
            product
            billingAccountId
            description
            updaterId
            ownerId
            approverId
            createdAt
            updatedAt
            version
            lastTransmittedDate
          }
          priceExecuted
          rejected
          terminated
          executionDatetime
          targetingStartDateTime
          targetingEndDateTime
          steps
          metrics {
            targeted
            reached
            engagements
            optedOut
            success
            interest
            confirmed
          }
          createdAt
          updatedAt
          version
          singleMsisdn
        }
        nextToken
      }
      name
      billingCodeId
      billingPremiumCodeId
      mno
      maxPrice
      bidPrice
      maxBudget
      targetRequested
      targetExcluded
      authorizedAmount
      handsetsToTarget
      targetable
      targeted
      exclusionListUri
      status
      validFrom
      validTo
      startTime
      endTime
      executionTimeRestrictionEnabled
      excludedDays
      targetMsisdnListUris
      templateId
      taxonomy
      product
      billingAccountId
      description
      updaterId
      ownerId
      approverId
      createdAt
      updatedAt
      version
      lastTransmittedDate
    }
  }
`;
export const updateJourneyOrder = /* GraphQL */ `
  mutation UpdateJourneyOrder(
    $input: UpdateJourneyOrderInput!
    $condition: ModelJourneyOrderConditionInput
  ) {
    updateJourneyOrder(input: $input, condition: $condition) {
      id
      definition {
        steps
        transitions {
          originatingStep
          destinationStep
          condition
          responseText
          task
        }
        billing
        metrics
        tags
        edr {
          providerId
          contentProvider
        }
        isDisabled
        reporting {
          introduction_text
          confirmation_text
          fulfillment_details
        }
      }
      executionStatuses {
        items {
          id
          journeyOrderId
          journeyOrder {
            id
            definition {
              steps
              billing
              metrics
              tags
              isDisabled
            }
            executionStatuses {
              nextToken
            }
            name
            billingCodeId
            billingPremiumCodeId
            mno
            maxPrice
            bidPrice
            maxBudget
            targetRequested
            targetExcluded
            authorizedAmount
            handsetsToTarget
            targetable
            targeted
            exclusionListUri
            status
            validFrom
            validTo
            startTime
            endTime
            executionTimeRestrictionEnabled
            excludedDays
            targetMsisdnListUris
            templateId
            taxonomy
            product
            billingAccountId
            description
            updaterId
            ownerId
            approverId
            createdAt
            updatedAt
            version
            lastTransmittedDate
          }
          priceExecuted
          rejected
          terminated
          executionDatetime
          targetingStartDateTime
          targetingEndDateTime
          steps
          metrics {
            targeted
            reached
            engagements
            optedOut
            success
            interest
            confirmed
          }
          createdAt
          updatedAt
          version
          singleMsisdn
        }
        nextToken
      }
      name
      billingCodeId
      billingPremiumCodeId
      mno
      maxPrice
      bidPrice
      maxBudget
      targetRequested
      targetExcluded
      authorizedAmount
      handsetsToTarget
      targetable
      targeted
      exclusionListUri
      status
      validFrom
      validTo
      startTime
      endTime
      executionTimeRestrictionEnabled
      excludedDays
      targetMsisdnListUris
      templateId
      taxonomy
      product
      billingAccountId
      description
      updaterId
      ownerId
      approverId
      createdAt
      updatedAt
      version
      lastTransmittedDate
    }
  }
`;
export const updateJourneyExecutionStatus = /* GraphQL */ `
  mutation UpdateJourneyExecutionStatus(
    $input: UpdateJourneyExecutionStatusInput!
    $condition: ModelJourneyExecutionStatusConditionInput
  ) {
    updateJourneyExecutionStatus(input: $input, condition: $condition) {
      id
      journeyOrderId
      journeyOrder {
        id
        definition {
          steps
          transitions {
            originatingStep
            destinationStep
            condition
            responseText
            task
          }
          billing
          metrics
          tags
          edr {
            providerId
            contentProvider
          }
          isDisabled
          reporting {
            introduction_text
            confirmation_text
            fulfillment_details
          }
        }
        executionStatuses {
          items {
            id
            journeyOrderId
            journeyOrder {
              id
              name
              billingCodeId
              billingPremiumCodeId
              mno
              maxPrice
              bidPrice
              maxBudget
              targetRequested
              targetExcluded
              authorizedAmount
              handsetsToTarget
              targetable
              targeted
              exclusionListUri
              status
              validFrom
              validTo
              startTime
              endTime
              executionTimeRestrictionEnabled
              excludedDays
              targetMsisdnListUris
              templateId
              taxonomy
              product
              billingAccountId
              description
              updaterId
              ownerId
              approverId
              createdAt
              updatedAt
              version
              lastTransmittedDate
            }
            priceExecuted
            rejected
            terminated
            executionDatetime
            targetingStartDateTime
            targetingEndDateTime
            steps
            metrics {
              targeted
              reached
              engagements
              optedOut
              success
              interest
              confirmed
            }
            createdAt
            updatedAt
            version
            singleMsisdn
          }
          nextToken
        }
        name
        billingCodeId
        billingPremiumCodeId
        mno
        maxPrice
        bidPrice
        maxBudget
        targetRequested
        targetExcluded
        authorizedAmount
        handsetsToTarget
        targetable
        targeted
        exclusionListUri
        status
        validFrom
        validTo
        startTime
        endTime
        executionTimeRestrictionEnabled
        excludedDays
        targetMsisdnListUris
        templateId
        taxonomy
        product
        billingAccountId
        description
        updaterId
        ownerId
        approverId
        createdAt
        updatedAt
        version
        lastTransmittedDate
      }
      priceExecuted
      rejected
      terminated
      executionDatetime
      targetingStartDateTime
      targetingEndDateTime
      steps
      metrics {
        targeted
        reached
        engagements
        optedOut
        success
        interest
        confirmed
      }
      createdAt
      updatedAt
      version
      singleMsisdn
    }
  }
`;
