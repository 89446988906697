import { createSlice, createSelector } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';
import { showMessage } from '../../../store/fuse/messageSlice';
import { existsOrEqualsZero } from '../../../services/generalUtil';
import { billingBaseQuery } from '../utils';

const invalidateCacheHeader = { headers: { 'Cache-Control': 'max-age=0' } };

export const accountsApi = createApi({
  reducerPath: 'accountsApi',
  baseQuery: billingBaseQuery,
  tagTypes: ['Accounts', 'AccountsInvalidated'],
  endpoints: (builder) => ({
    getAccounts: builder.query({
      query: () => ({
        url: 'accounts',
        method: 'GET',
      }),
      transformResponse: (response) =>
        response.map((account) =>
          Object.fromEntries(Object.entries(account).filter(([k, v]) => existsOrEqualsZero(v)))
        ),
      providesTags: ['Accounts'],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          dispatch(showMessage({ message: 'Failed to fetch accounts.', variant: 'error' }));
        }
      },
    }),
    getAccountsInvalidated: builder.query({
      query: () => ({
        url: 'accounts',
        method: 'GET',
        headers: invalidateCacheHeader.headers, // Include the invalidateCacheHeader
      }),
      transformResponse: (response) =>
        response.map((account) =>
          Object.fromEntries(Object.entries(account).filter(([k, v]) => existsOrEqualsZero(v)))
        ),
      providesTags: ['AccountsInvalidated'],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          dispatch(showMessage({ message: 'Failed to fetch accounts.', variant: 'error' }));
        }
      },
    }),
    saveAccount: builder.mutation({
      query: (data) => ({
        url: 'accounts',
        method: 'PUT',
        body: Object.fromEntries(
          Object.entries(data).map(([k, v]) => (v === '' ? [k, null] : [k, v]))
        ),
      }),
      invalidatesTags: ['AccountsInvalidated'],
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(showMessage({ message: 'Account saved successfully!', variant: 'success' }));
        } catch (err) {
          dispatch(showMessage({ message: 'Account already exists!', variant: 'error' }));
        }
      },
    }),
  }),
});

export const {
  useGetAccountsQuery,
  useGetAccountsInvalidatedQuery,
  useSaveAccountMutation,
} = accountsApi;
