import _ from '@lodash';
import { Constants, JourneyStepTypeMap, OrderStatus } from 'app/services/constants';
import { hasUserSupportRole } from 'app/services/permissionUtil';
import { HANDSET_OPTIONS_ALL } from 'app/shared-components/FormHandsetDropdown';
import ComponentLogger from 'app/services/logger/ComponentLogger';
import { getElements, getLayoutedElements } from 'app/shared-components/flow/FlowHelper';
import { getS3FileSize } from '../store/orderSlice';

const logger = new ComponentLogger('orderHelper');

export const isEditable = (element) => {
  return !element.data?.readOnly && JourneyStepTypeMap[element.type]
    ? JourneyStepTypeMap[element.type].isEditable
    : false;
};

function getKeyValueList(tagMap) {
  if (tagMap) {
    return Object.entries(JSON.parse(tagMap)).map(([key, value]) => ({
      name: key,
      value,
    }));
  }
  return [];
}

export function setValueAtPath(obj, path, value) {
  const keys = path.split('.');
  keys.reduce((acc, key, index) => {
    if (index === keys.length - 1) {
      acc[key] = value;
    }
    return acc[key] || (acc[key] = {});
  }, obj);
}

export function updateStepsWithVariables(steps, variables) {
  if (variables) {
    variables.forEach(({ path, value }) => {
      setValueAtPath(steps, path, value);
    });
  }
  return steps;
}

export function getStepsFromJourneyTemplate(template, isSupportRole) {
  if (template && template.journey) {
    const { journeyID, ...restTemplate } = template;
    const { id, ...restJourney } = restTemplate.journey;
    const layoutItems = getLayoutedElements(
      getElements(
        updateStepsWithVariables(JSON.parse(restJourney.steps), template.variables),
        restJourney.transitions,
        isSupportRole
      )
    );
    const steps = [...layoutItems.nodeList, ...layoutItems.edgeList];

    const tags = getKeyValueList(restJourney.tags);

    return { ...restTemplate, journey: { ...restJourney, steps, tags } };
  }
  return null;
}

export function isFieldRequired(stepType, field) {
  return JourneyStepTypeMap[stepType]
    ? JourneyStepTypeMap[stepType].required && JourneyStepTypeMap[stepType].required.includes(field)
    : false;
}

export function getRequiredFields(stepType) {
  return JourneyStepTypeMap[stepType] ? JourneyStepTypeMap[stepType].required : null;
}

export function getJourneyFromJourneyDefinition(definition, isSupportRole, template = null) {
  if (definition) {
    const definitionSteps = JSON.parse(definition.steps);

    const layoutItems = getLayoutedElements(
      getElements(
        template ? getStepsWithInfoFromTemplate(definitionSteps, template) : definitionSteps,
        definition.transitions,
        isSupportRole
      )
    );
    const steps = [...layoutItems.nodeList, ...layoutItems.edgeList];

    const tags = getKeyValueList(definition.tags);

    const res = {
      ...definition,
      steps,
      tags,
    };
    return res;
  }
  return null;
}

function getStepsWithInfoFromTemplate(steps, template) {
  const templateSteps = JSON.parse(template.journey.steps);
  Object.entries(steps).forEach(([id, step]) => {
    const templateStep = templateSteps[id];
    if (templateStep && templateStep.info) step.info = templateStep.info;
  });
  return steps;
}

export function cloneOrder(defaultValues, order, role, userSettings) {
  const { cloneDeep, assign, pick, keys } = _;
  const cloned = cloneDeep(defaultValues);
  assign(cloned.definition, pick(order.definition, keys(cloned.definition)));

  const cloneableKeys = keys(cloned).filter((key) => key !== 'definition' && key !== 'status');
  assign(cloned, pick(order, cloneableKeys));

  cloned.excludedDays = updateExcludedDays(cloned.excludedDays);

  if (isStartDateInvalid(cloned.validFrom))
    [cloned.validFrom, cloned.validTo] = getShiftedDates(cloned.validFrom, cloned.validTo);

  if (!cloned.handsetsToTarget && userSettings?.deviceTypeTargeting)
    cloned.handsetsToTarget = HANDSET_OPTIONS_ALL;

  cloned.definition = getJourneyFromJourneyDefinition(cloned.definition, hasUserSupportRole(role));
  return cloned;
}

function updateExcludedDays(excludedDays) {
  return !excludedDays || !excludedDays.length || !excludedDays.includes(7)
    ? [...(excludedDays || []), 7]
    : excludedDays;
}

export function isStartDateInvalid(startDateStr) {
  return Date.parse(startDateStr, Constants.DEFAULT_DATE_FORMAT) < getBeginningOfToday();
}

export function getBeginningOfToday() {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return today;
}

export function getShiftedDates(startDateStr, endDateStr) {
  const startDate = Date.parse(startDateStr, Constants.DEFAULT_DATE_FORMAT);
  const endDate = Date.parse(endDateStr, Constants.DEFAULT_DATE_FORMAT);
  return [formatDate(new Date()), formatDate(new Date(Date.now() + endDate - startDate))];
}

export function formatDate(date) {
  return date.toISOString().slice(0, 10);
}

export function getOrderStatusByName(value) {
  return OrderStatus[
    Object.keys(OrderStatus).find(
      (key) => OrderStatus[key].name.toLowerCase() === value.toLowerCase()
    )
  ];
}

export function getOrderStatus(journeyOrder) {
  if (journeyOrder.status) {
    return getOrderStatusByName(journeyOrder.status);
  }
  return OrderStatus.SCHEDULED;
}

export function getNodeTitle(stepType) {
  return JourneyStepTypeMap[stepType] ? JourneyStepTypeMap[stepType].title : null;
}

export function getDecimalScale() {
  return 2;
}

export async function estimateFileUploadMsisdnCount(filePaths) {
  if (filePaths?.length) {
    const promises = [];
    filePaths.map((path) =>
      promises.push(getS3FileSize(path.substring(path.lastIndexOf('/') + 1)))
    );

    try {
      const fileSizes = await Promise.all(promises);
      return Math.floor(
        fileSizes.reduce((total, fileSize) => {
          return total + fileSize / Constants.FILE_UPLOAD_LINE_BYTES;
        }, 0)
      );
    } catch (error) {
      logger.error('estimateFileUploadMsisdnCount', { error });
    }
  }
  return 0;
}
