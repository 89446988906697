import { createApi } from '@reduxjs/toolkit/query/react';
import { showMessage } from 'app/store/fuse/messageSlice';
import { billingBaseQuery } from '../utils';

const invalidateCacheHeader = { headers: { 'Cache-Control': 'max-age=0' } };

export const contractsApi = createApi({
  reducerPath: 'contractsApi',
  baseQuery: billingBaseQuery,
  tagTypes: ['Contracts'],
  endpoints: (builder) => ({
    getContracts: builder.query({
      query: (invalidate = false) => ({
        url: 'contracts',
        method: 'GET',
        headers: invalidate ? invalidateCacheHeader.headers : {},
      }),
      providesTags: ['Contracts'],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          dispatch(
            showMessage({
              message: 'Failed to fetch contracts. Please try again later.',
              variant: 'error',
            })
          );
        }
      },
    }),

    saveContract: builder.mutation({
      query: (payload) => ({
        url: 'contracts',
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['Contracts'],
      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            showMessage({
              message: 'Contract saved successfully!',
              variant: 'success',
            })
          );
        } catch (error) {
          dispatch(
            showMessage({
              message: 'An error occurred while saving the contract.',
              variant: 'error',
            })
          );
        }
      },
    }),
  }),
});

// Export hooks for usage in components
export const {
  useGetContractsQuery,
  useSaveContractMutation,
} = contractsApi;
