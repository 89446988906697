import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormInputDropdown from './FormInputDropdown';
import { getAccountList } from '../services/permissionUtil';
import { useGetAccountsQuery } from '../main/billing-admin/store/accountsApi';
import FuseLoading from '@fuse/core/FuseLoading';

function FormAccountDropdown({ mno, displayOnlyAccountName, additionaFilter, ...props }) {
  const dispatch = useDispatch();
  const { permissions } = useSelector(({ auth }) => auth.user);
  const { data: accounts, error, isLoading } = useGetAccountsQuery();
  const [availableAccounts, setAvailableAccounts] = useState([]);

  useEffect(() => {
    const filterCondition = (account) =>
      account.mno === mno && (!additionaFilter || additionaFilter(account));
    if (mno && accounts) {
      setAvailableAccounts(
        getAccountList(permissions, accounts, mno)
          .filter(filterCondition)
          .sort((acc1, acc2) => acc1.name.localeCompare(acc2.name))
          .map((account) => {
            return {
              value: account.id,
              label:
                account.name === account.mno || displayOnlyAccountName
                  ? account.name
                  : `${account.name} (${account.mno})`,
            };
          })
      );
    }
  }, [mno, accounts]);

  if (isLoading) {
    return <FuseLoading />;
  }

  return <FormInputDropdown {...props} options={availableAccounts} />;
}

export default FormAccountDropdown;
