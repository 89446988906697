import { createApi } from '@reduxjs/toolkit/query/react';
import ComponentLogger from 'app/services/logger/ComponentLogger';
import { showMessage } from 'app/store/fuse/messageSlice';
import { billingBaseQuery } from '../utils';

const invalidateCacheHeader = { headers: { 'Cache-Control': 'max-age=0' } };
const logger = new ComponentLogger('CorrectionsApi');

const parseProductDetail = (productDetail) => {
  if (productDetail === '') {
    return { part1: '', part2: '' };
  }
  const arr = productDetail.split(',');
  if (arr.length === 1) {
    return arr[0].startsWith('providerId=')
      ? { part1: '', part2: arr[0].split('providerId=')[1] }
      : { part1: arr[0], part2: '' };
  }
  if (arr.length === 2) {
    return { part1: arr[0], part2: arr[1].split('providerId=')[1] };
  }
  return { part1: '', part2: '' };
};

const getAccountNames = (accounts, accountId) => {
  const result = accounts.find((item) => item.id === accountId);
  if (result) return result.name === result.mno ? result.name : `${result.name} (${result.mno})`;
  return accountId;
};

const getPlatformName = (platforms, platformId) => {
  const result = platforms.find((item) => item.id === platformId);
  if (result) return result.name === result.mno ? result.name : `${result.name} (${result.mno})`;
  return platformId;
};

export const getContractName = (contracts, contractId) => {
  const result = contracts.find((item) => item.id === contractId);
  if (result) return result.name === result.mno ? result.name : `${result.name} (${result.id})`;
  return contractId === '0' ? 'NOT SET' : contractId;
};

const generateProductDetail = (customerVisibleDescription, providerId) => {
  const descStr = customerVisibleDescription !== '' ? `${customerVisibleDescription}` : '';
  const provId = providerId !== '' ? `providerId=${providerId}` : '';
  if (provId === '') return descStr;
  if (descStr === '') return provId;
  return `${descStr},${provId}`;
};

export const correctionsApi = createApi({
  reducerPath: 'correctionsApi',
  baseQuery: billingBaseQuery,
  tagTypes: ['Corrections'],
  endpoints: (builder) => ({
    getCorrections: builder.query({
      queryFn: async (invalidate, _queryApi, _extraOptions, fetchWithBQ) => {
        try {
          const correctionsResponse = await fetchWithBQ({
            url: 'corrections',
            method: 'GET',
            headers: invalidate ? invalidateCacheHeader.headers : {},
          });

          if (correctionsResponse.error) throw correctionsResponse.error;

          const [billingAccountsResponse, billingPlatformsResponse, contractsResponse] =
            await Promise.all([
              fetchWithBQ({ url: 'accounts', method: 'GET' }),
              fetchWithBQ({ url: 'platforms', method: 'GET' }),
              fetchWithBQ({ url: 'contracts', method: 'GET' }),
            ]);

          if (billingAccountsResponse.error) throw billingAccountsResponse.error;
          if (billingPlatformsResponse.error) throw billingPlatformsResponse.error;
          if (contractsResponse.error) throw contractsResponse.error;

          const billingAccounts = billingAccountsResponse.data;
          const billingPlatforms = billingPlatformsResponse.data;
          const contracts = contractsResponse.data;

          const response = correctionsResponse.data
            .sort((correction1, correction2) =>
              correction2.dateEntered?.localeCompare(correction1.dateEntered)
            )
            .map((correction) => {
              const { part1, part2 } = parseProductDetail(correction.productDetail);
              return {
                ...correction,
                billingAccountName: getAccountNames(billingAccounts, correction.billingAccountId),
                platformName: getPlatformName(billingPlatforms, correction.platformId),
                contractName: getContractName(contracts, correction.contractId),
                customerVisibleDescription: part1,
                contentProviderId: part2,
              };
            });
          return { data: response };
        } catch (error) {
          return { error };
        }
      },
      providesTags: ['Corrections'],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          // Show notification on error
          dispatch(
            showMessage({
              variant: 'error',
              message: 'Failed to fetch corrections. Please try again later.',
            })
          );
        }
      },
    }),
    saveCorrection: builder.mutation({
      query: (correction) => {
        // Generate productDetail and prepare the request body
        const productDetail = generateProductDetail(
          correction.customerVisibleDescription,
          correction.contentProviderId
        );
        const requestBody = {
          ...correction,
          productDetail,
        };
        delete requestBody.customerVisibleDescription;
        delete requestBody.contentProviderId;

        return {
          url: 'corrections',
          method: 'POST',
          body: requestBody,
        };
      },
      invalidatesTags: ['Corrections'], // Ensures the corrections list is refreshed
      async onQueryStarted(correction, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            showMessage({
              message: 'Correction saved successfully!',
              variant: 'success',
            })
          );
        } catch (err) {
          dispatch(
            showMessage({
              message: 'An error occurred while saving the correction.',
              variant: 'error',
            })
          );
        }
      },
    }),
    deleteCorrection: builder.mutation({
      query: (id) => ({
        url: `corrections/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Corrections'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data: response } = await queryFulfilled;

          if (response === 1) {
            // Notify success
            dispatch(
              showMessage({
                message: 'Correction record has been deleted successfully!',
                variant: 'success',
              })
            );
          } else {
            // Notify failure
            dispatch(
              showMessage({
                message:
                  'Correction record could not be deleted! Please refresh the page and try again!',
                variant: 'error',
              })
            );
          }
        } catch (error) {
          // Log error and notify failure
          logger.error('deleteCorrection', { error });
          dispatch(
            showMessage({
              message: 'An error occurred while deleting the correction!',
              variant: 'error',
            })
          );
        }
      },
    }),
  }),
});

export const {
  useGetCorrectionsQuery,
  useLazyGetCorrectionsQuery,
  useSaveCorrectionMutation,
  useDeleteCorrectionMutation,
} = correctionsApi;
