import ReactFlow, {
  Background,
  Controls,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
} from 'reactflow';
import { Card } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';

import ContextMenu from 'app/shared-components/flow/ContextMenu';
import CustomSmartBezierButtonEdge from 'app/shared-components/flow/CustomSmartBezierButtonEdge';
import 'reactflow/dist/base.css';
import { getLayoutedElements, getStepComponents } from 'app/shared-components/flow/FlowHelper';

const connectionLineStyle = { stroke: '#ddd', fontSize: '0.5rem' };

function FlowComponent(props) {
  const { fields, onElementClick } = props;
  const ref = useRef(null);

  const [menu] = useState(null);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const proOptions = { hideAttribution: true };
  const edgeTypes = useMemo(
    () => ({
      smart: CustomSmartBezierButtonEdge,
    }),
    []
  );

  const nodeTypes = useMemo(() => getStepComponents('node'), []);

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  useEffect(() => {
    if (fields && fields.length) {
      const layoutedElements = getLayoutedElements(fields);
      setNodes(layoutedElements.nodeList);
      setEdges(layoutedElements.edgeList);
    }
  }, [fields]);

  useEffect(() => {
    if (reactFlowInstance && nodes && nodes.length) {
      setTimeout(() => reactFlowInstance.fitView(), 0);
    }
  }, [reactFlowInstance, nodes]);

  return (
    <Card className="w-full rounded-20 shadow p-5">
      <div style={{ height: 700 }}>
        {fields?.length ? (
          <ReactFlowProvider>
            <ReactFlow
              ref={ref}
              nodes={nodes}
              edges={edges}
              nodeTypes={nodeTypes}
              edgeTypes={edgeTypes}
              nodeExtent={[
                [0, 0],
                [2000, 3000],
              ]}
              minZoom={0.1}
              onNodeClick={onElementClick}
              onInit={(instance) => setReactFlowInstance(instance)}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              connectionLineStyle={connectionLineStyle}
              proOptions={proOptions}
              fitView
              snapToGrid
              nodesDraggable={false}
              zoomOnScroll={false}
            >
              <Controls showInteractive={false} />
              <Background color="#aaa" gap={20} />
              {menu && <ContextMenu {...menu} />}
            </ReactFlow>
          </ReactFlowProvider>
        ) : (
          <></>
        )}
      </div>
    </Card>
  );
}

export default FlowComponent;
