import { createApi } from '@reduxjs/toolkit/query/react';
import { showMessage } from '../../../store/fuse/messageSlice';
import { billingBaseQuery } from '../utils';

const invalidateCacheHeader = { headers: { 'Cache-Control': 'max-age=0' } };

export const paymentEntryApi = createApi({
  reducerPath: 'paymentEntryApi',
  baseQuery: billingBaseQuery,
  endpoints: (builder) => ({
    savePaymentEntry: builder.mutation({
      query: (data) => ({
        url: `accounts/${data.billingAccountId}/payments`,
        method: 'PUT',
        body: Object.fromEntries(
          Object.entries(data).map(([k, v]) => (v === '' ? [k, null] : [k, v]))
        ),
      }),
    }),
  }),
});

export const { useSavePaymentEntryMutation } = paymentEntryApi;
