import { yupResolver } from '@hookform/resolvers/yup';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';

import _ from '@lodash';
import * as yup from 'yup';

import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import format from 'date-fns/format';
import { parseISO } from 'date-fns';
import FormInputText from '../../../shared-components/FormInputText';
import { closePriceDialog } from '../store/pricesSlice';
import FormInputDropdown from '../../../shared-components/FormInputDropdown';
import { Constants } from '../../../services/constants';
import FormContractDropdown from '../../../shared-components/FormContractDropdown';
import { useGetPricesOfCodeQuery, useSavePriceMutation } from '../store/pricesApi';

const defaultValues = {
  validFrom: null,
  validTo: null,
  contractId: null,
  subscriberPrice: '',
  subscriberPriceCurrency: '',
  edrPrice: 0,
  edrPriceCurrency: '',
};

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  id: yup.string().nullable(),
  validFrom: yup.date().nullable().max(yup.ref('validTo'), "Valid from date can't be after valid to"),
  validTo: yup.date().nullable().min(yup.ref('validFrom'), "Valid to date can't be before valid from"),
  codeId: yup.string().nullable(),
  contractId: yup.string().notOneOf(["",'0'], "Please select the contract"),
  subscriberPrice: yup.lazy((value) =>
    value === '' ? yup.string() : yup.number().required('You must enter a subscriber price')
  ),
  subscriberPriceCurrency: yup.string().required('You must enter a subscriber price currency'),
  edrPrice: yup.lazy((value) => (value === '' ? yup.string().nullable() : yup.number().nullable())),
  edrPriceCurrency: yup.string().nullable(),
});

const currencyList = process.env.REACT_APP_CURRENCY_LIST.split(',');

function PriceDialog({selectedMno}) {
  const dispatch = useDispatch();
  const priceDialog = useSelector(({ billing }) => billing.prices.priceDialog);
  const price = priceDialog.data;
  const isNew = !price || !price.id;
  const [save] = useSavePriceMutation();
  const { control, reset, getValues, formState, trigger } = useForm({
    mode: 'onChange',
    defaultValues: {
      ...defaultValues,
      ...price,
    },
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields } = formState;

  useEffect(() => {
    if (priceDialog.props.open) {
      reset({
        ...defaultValues,
        ...price,
      });
    }
  }, [price, priceDialog.props.open, reset]);

  function handleSavePrice() {
    const data = Object.fromEntries(
      Object.entries(getValues())
        .map(([k, v]) => {
          let val = v;
          if (k in schema.fields) {
            if (schema.fields[k].type === 'date' && v) {
              val = format(parseISO(v), Constants.DEFAULT_DATE_FORMAT);
            } else if (schema.fields[k].type === 'lazy') {
              val = parseFloat(v);
            }
          }

          return [k, val];
        })
        .filter(([k, v]) => (!!v || !Number.isNaN(v)) && k in schema.fields)
    );
    save(data);
    dispatch(closePriceDialog());
  }

  function handleValidTimeChange() {
    trigger('validFrom');
    trigger('validTo');
  }

  return (
    <Dialog
      classes={{
        paper: 'm-24',
      }}
      {...priceDialog.props}
      onClose={() => dispatch(closePriceDialog())}
      fullWidth
      maxWidth="xs"
    >
      <AppBar position="static" elevation={0}>
        <Toolbar className="flex w-full">
          <Typography variant="subtitle1" color="inherit">
            {isNew ? 'New Price or Price Percentage' : 'Edit Price or Price Percentage'}
          </Typography>
        </Toolbar>
      </AppBar>
      <form noValidate className="flex flex-col md:overflow-hidden">
        <DialogContent classes={{ root: 'p-24' }}>
          <Grid container direction="column" rowSpacing={2}>
            <Grid item>
              <FormInputText
                label="Valid From"
                name="validFrom"
                control={control}
                type="date"
                customHandler={handleValidTimeChange}
              />
            </Grid>
            <Grid item>
              <FormInputText
                label="Valid To"
                name="validTo"
                control={control}
                type="date"
                customHandler={handleValidTimeChange}
              />
            </Grid>
            <Grid item>
              <FormContractDropdown
                name="contractId"
                label="Contract"
                control={control}
                mno={selectedMno}
                required
              />
            </Grid>
            <Grid item>
              <FormInputText
                label="Subscriber Price"
                name="subscriberPrice"
                control={control}
                type="number"
                required
              />
            </Grid>
            <Grid item>
              <FormInputDropdown
                label="Subscriber Price Currency"
                name="subscriberPriceCurrency"
                control={control}
                required
                options={currencyList.map((val) => ({ value: val }))}
              />
            </Grid>
            <Grid item>
              <FormInputText label="Edr Price" name="edrPrice" control={control} type="number" />
            </Grid>
            <Grid item>
              <FormInputDropdown
                label="Edr Price Currency"
                name="edrPriceCurrency"
                control={control}
                options={currencyList.map((val) => ({ value: val }))}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="justify-between p-4 pb-16">
          <div className="px-16">
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSavePrice}
              disabled={_.isEmpty(dirtyFields) || !isValid}
            >
              {isNew ? 'Add' : 'Save'}
            </Button>
          </div>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default PriceDialog;
