import { IconButton, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import withRouter from '../../../../@fuse/core/withRouter';

function TemplatesAction(props) {
  return (
    <>
      {
        <Tooltip title="Clone">
          <IconButton
            aria-label="clone"
            size="medium"
            tooltip="Clone"
            onClick={() =>
              props.navigate(`/support/template/clone`, { state: props.cellValues.row })
            }
          >
            <ContentCopyIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      }
      {
        <Tooltip title="Edit">
          <IconButton
            aria-label="Edit"
            size="medium"
            onClick={() => props.navigate(`/support/template/${props.cellValues.row.id}`)}
          >
            <EditOutlinedIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      }
    </>
  );
}

export default withRouter(TemplatesAction);
